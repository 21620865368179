import { SignatureItem } from '../../../models/survey-items/question-items/signatureItem';
import {
    AfterViewInit,
    Component,
    Input,
    OnInit,
    ViewChild,
    ViewEncapsulation,
    ElementRef,
    HostListener,
    OnDestroy,
    ChangeDetectorRef
} from '@angular/core';
import {
    dataURItoBlob,
    blobToDataURL
} from '../../../../infrastructure/helpers/dataURItoBlob.helper';
import { SharedService } from '../../../../infrastructure/services';
import { TakeSurveyProvider } from '../../../../app-take-survey/providers';
import { TakeSurveyData } from '../../../../infrastructure/consts/take-survey.consts';
import { getQuestionItemInnerCssClass } from '../../../../infrastructure/helpers/surveys.helper';
import { PrintService } from '../../../../infrastructure/services';
import { SurveyDefaultText } from '../../../../infrastructure/consts/surveyDefaultText';
import { Subscription } from 'rxjs';
import { SignaturePadComponent } from '@almothafar/angular-signature-pad';

@Component({
    selector: 'cb-signature-item-preview',
    templateUrl: './signature-item-preview.component.html',
    styleUrls: ['./signature-item-preview.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SignaturePreviewItemComponent implements OnInit, AfterViewInit, OnDestroy {
    // Todo migration: SignaturePadComponent should be tested
    @ViewChild(SignaturePadComponent) signaturePad: SignaturePadComponent;
    @Input() questionItem: SignatureItem;
    @Input() isTakeSurvey: boolean;
    fileData: FormData;
    signaturePadOptions: Object;
    getQuestionItemInnerCssClass = getQuestionItemInnerCssClass;
    @ViewChild('signature', { static: true }) private signature: ElementRef;
    questionWrapperWidth: number;
    surveyId: number;
    responseId: any;
    surveyText = SurveyDefaultText;
    textSubscription: Subscription;
    showCanvas = true;

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (this.questionWrapperWidth !== this.signature.nativeElement.offsetWidth) {
            this.questionWrapperWidth = this.signature.nativeElement.offsetWidth;
            this.resizeCanvas();
        }
    }

    constructor(
        private sharedService: SharedService,
        private changeDetectorRef: ChangeDetectorRef,
        private surveyResponseProvider: TakeSurveyProvider,
        private printService: PrintService,
        private elem: ElementRef
        ) { }

    ngOnInit() {
        this.signaturePadOptions = {
            minWidth: 5,
            canvasWidth: this.questionItem.width
                ? this.questionItem.width
                : 250,
            canvasHeight: this.questionItem.height
                ? this.questionItem.height
                : 250,
            penColor: this.questionItem.pen_color
                ? this.questionItem.pen_color
                : '#000',
            'background-color': 'inherit'
        };

        if (this.isTakeSurvey) {
            this.surveyId = this.questionItem.surveyId;
            this.responseId = this.questionItem.responseId;
        }

        this.textSubscription = this.sharedService.surveyText.subscribe(text => {
            this.surveyText = { ...text };
            if (!this.changeDetectorRef['destroyed']) {
                this.changeDetectorRef.detectChanges();
            }
        });
        this.showCanvas = !(this.questionItem.file_id && this.surveyId && this.responseId);
    }

    ngAfterViewInit() {
        this.questionWrapperWidth = this.signature.nativeElement.offsetWidth;
        this.resizeCanvas();
        this.printService.waitForImageLoadingAndMarkReady(this.questionItem.id, this.elem);
    }

    resizeCanvas() {
        const canvas = this.signature.nativeElement.querySelector('canvas');
        const ratio = Math.max(window.devicePixelRatio || 1, 1);

        if (canvas) {
            canvas.style.width = this.questionWrapperWidth < canvas.offsetWidth ? this.questionWrapperWidth + 'px' : canvas.offsetWidth + 'px';
            canvas.style.height = canvas.offsetHeight + 'px';
            canvas.width = this.questionWrapperWidth < canvas.offsetWidth ? this.questionWrapperWidth : canvas.offsetWidth * ratio;
            canvas.height = canvas.offsetHeight * ratio;

            canvas.getContext('2d').scale(ratio, ratio);
            this.signaturePad?.clear(); // otherwise isEmpty() might return incorrect value
        }
    }

    drawComplete() {
        const currentDate = new Date();
        const blob = dataURItoBlob(this.signaturePad.toDataURL());
        this.fileData = new FormData();
        this.fileData.append(
            'image',
            blob,
            `Signature_${
                this.questionItem.id
            }_${currentDate.getFullYear()}${currentDate.getMonth() +
                1}${currentDate.getDate()}.png`
        );

        this.sharedService.share(TakeSurveyData.SIGNATURE_FORM_DATA, {
            item_id: this.questionItem.id,
            formData: this.fileData
        });
    }
    clearSignaturePad(){
        this.showCanvas = true;
        this.questionItem.file_id = this.questionItem["file_name"] = null;
        if (this.questionItem.answer) {
            this.questionItem.answer = null;
        }
        this.signaturePad?.clear();
    }
    resetForm() {
        this.signaturePad?.clear();
    }

    ngOnDestroy() {
        this.textSubscription.unsubscribe();
    }
}
