import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Inject
} from '@angular/core';
import { SurveyEditorProvider, SurveysProvider, SurveyPagesProvider } from '../../../../providers';
import { first, distinctUntilChanged } from 'rxjs/operators';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { SurveysPageTypes,
SurveyQuestionType,
SurveyDisplayType,
SurveyActionType,
SurveyReportType,
SurveysPagesActions
} from '../../../../../infrastructure/consts/surveys.consts';
import { ItemPreviewDialogComponent } from '../item-preview-dialog/item-preview-dialog.component';
import { SharedService } from '../../../../../infrastructure/services';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { stripHtml } from '../../../../../infrastructure/helpers/strip-html.helper';
import { getShortenedString } from '../../../../../infrastructure/helpers/string.helpers';
import { forkJoin } from 'rxjs';

@Component({
    selector: 'cb-add-from-template-dialog',
    templateUrl: './add-from-template-dialog.component.html',
    styleUrls: ['./add-from-template-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class AddFromTemplateDialogComponent implements OnInit {

    public isLoading: boolean;
    public isLoadingTemplates: boolean;
    public teplateList;
    public templateQuestions;
    public templateQuestionsLength = 0;
    public pageSize = 10;
    public sortedTemplateQuestions;
    public form: UntypedFormGroup;
    public cachedTemplates = new Map();
    public displayedColumns: string[] = [
        'select',
        'questionTitle',
        'type'
    ];
    private pageId: number;
    private surveyId: number;
    private pageType: string;
    private completionPageQuestions;
    public selection = new SelectionModel<any>(true, [], true);

    constructor(
        private surveyEditorProvider: SurveyEditorProvider,
        private surveyPagesProvider: SurveyPagesProvider,
        private surveyProvider: SurveysProvider,
        private cdr: ChangeDetectorRef,
        private fb: UntypedFormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<AddFromTemplateDialogComponent>,
        private dialog: MatDialog,
        private sharedService: SharedService
    ) {}

    ngOnInit(): void {
        this.prepareData();
        this.getTemplatesList();
        this.generateForm();
    }

    prepareData() {
        this.completionPageQuestions = [...Object.values(SurveyReportType),
            ...Object.values(SurveyActionType),
           ...Object.values(SurveyDisplayType)];
       this.pageId = this.data.pageId;
       this.surveyId = this.data.surveyId;
       this.pageType = this.data.pageType;
       if (this.pageType !== SurveysPageTypes.HiddenItems) {
           this.displayedColumns.push('actions');
       }
    }

    generateForm() {
        this.form = this.fb.group({
            templateId: this.fb.control('')
        });
        this.form.valueChanges.pipe(
            distinctUntilChanged()
        ).subscribe(
            data => {
                const cached = this.cachedTemplates.get(data.templateId);
                if (!cached) {
                    this.getTemplateQuestion(data.templateId);
                } else {
                    this.templateQuestions = cached;
                    this.templateQuestionsLength = this.templateQuestions.length;
                    this.sortedTemplateQuestions = this.templateQuestions.slice(0, this.pageSize);
                    this.detectChanges();
                }
            }
        );
    }

    getTemplatesList() {
        this.isLoading = true;
        this.detectChanges();
        this.surveyProvider.getSurveys( null, true).pipe(
            first()
        ).subscribe(
            data => {
                this.teplateList = data.items;
                this.isLoading = false;
                this.detectChanges();
            },
            error => this.onError()
        );
    }

    filterQuestionsByPageType(questions) {
        switch (this.pageType) {
            case SurveysPageTypes.ContentPage:
                return questions.filter(item => item.item_type !== SurveyQuestionType.HIDDEN_ITEM);
            case SurveysPageTypes.Completion:
                return questions.filter(item => this.completionPageQuestions.includes(item.item_type));
            case SurveysPageTypes.HiddenItems:
                return questions.filter(item => item.item_type === SurveyQuestionType.HIDDEN_ITEM);
        }
    }

    getTemplateQuestion(templateId) {
        this.isLoadingTemplates = true;
        this.detectChanges();
        const pages = this.surveyPagesProvider.getAllPages(templateId, true);
        const questions = this.surveyEditorProvider.getAllQuestions( templateId, true)
        forkJoin(pages, questions).pipe(
            first()
        ).subscribe(
            ([pagesRes, questionsRes]) => {
                const pagesPosById = {};
                pagesRes.forEach(item =>  pagesPosById[item.id] = item.position);
                const filtredQuestions = this.filterQuestionsByPageType(questionsRes);
                filtredQuestions.forEach(item => item['page_position'] = pagesPosById[item.page_id]);
                filtredQuestions.sort((a, b) => a.page_position - b.page_position || a.position - b.position);
                this.templateQuestions = filtredQuestions;
                this.templateQuestionsLength = this.templateQuestions.length;
                this.sortedTemplateQuestions = this.templateQuestions.slice(0, this.pageSize);
                this.cachedTemplates.set(templateId, filtredQuestions);
                this.selection.clear();
                this.isLoadingTemplates = false;
                this.detectChanges();
            }, err => this.onError()
        );
    }

    detectChanges() {
        if (!this.cdr['destroyed']) {
            this.cdr.detectChanges();
        }
    }

    onError() {
        this.isLoading = false;
        this.isLoadingTemplates = false;
        this.detectChanges();
    }

    public isAllSelected(): boolean {
        const numSelected = this.selection.selected.length;
        const numRows = this.templateQuestions.length;
        return numSelected === numRows;
    }

    public toggleAll() {
        this.isAllSelected() ? this.selection.clear() : this.templateQuestions.forEach(row => this.selection.select(row));
    }

    onSubmit() {
        const templateId = this.form.value.templateId;
        const questionIds = this.selection.selected.map(
            item => item.id
        );

        const data = {
            page_id: this.pageId,
            survey_template_id: templateId,
            item_ids: questionIds
        };
        this.sharedService.share(
            SurveysPagesActions.ACTIVATE_SURVEY_ITEM,
            data
        );
        this.surveyEditorProvider.addQuestionFromTemplate(this.surveyId, data).pipe(
            first()
        ).subscribe(
            newItems =>  {
                this.sharedService.share(SurveysPagesActions.ADD_SURVEY_ITEM_FROM_TEMPLATE, newItems);
                this.dialogRef.close();
            },
            error => this.onError()
        );
    }

    clearQuestiontext(text) {
        return  text ? getShortenedString(stripHtml(text), 100) : '';
    }

    pageChanged(ev) {
        this.pageSize = ev.pageSize;
        const startIndex = ev.pageIndex * this.pageSize;
        const lastIndex = startIndex + this.pageSize;
        this.sortedTemplateQuestions = this.templateQuestions.slice(startIndex, lastIndex);
    }

    openPreview(item) {
        this.dialog.open(
            ItemPreviewDialogComponent,
            {
                width: '1068px',
                data: {item}
            }
        );
    }
}
