<cb-main-dialog [title]="'RENAME-SURVEY-DIALOG.RENAME_SURVEY' | translate" [mainButton]="'SHARED.SAVE' | translate"
[cancelButton]="'SHARED.CANCEL' | translate" [disabled]="disableSubmitButton()"
(onMain)="onSubmit()" (onCancel)="close()">
    <cb-spinner *ngIf="isLoading" [show]="true"></cb-spinner>
    <form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate *ngIf="!isLoading">
        <div>
            <label for="name">{{'RENAME-SURVEY-DIALOG.SURVEY_TITLE' | translate}}</label>
            <mat-form-field class="no-label no-top dialog-form" appearance="outline">
                <mat-label>{{
                        'RENAME-SURVEY-DIALOG.SURVEY_TITLE' | translate
                    }}</mat-label>
                <input matInput formControlName="name" maxlength="255" />
                <mat-error>
                    {{
                    'RENAME-SURVEY-DIALOG.VALIDATION-MESSAGE.SURVEY_TITLE'
                    | translate
                    }}
                </mat-error>
            </mat-form-field>
        </div>
    </form>
</cb-main-dialog>
