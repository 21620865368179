import { Component, OnInit, Output, EventEmitter, OnDestroy, Input} from '@angular/core';
import { FormBuilder, UntypedFormControl, FormGroup } from '@angular/forms';
import { debounceTime, distinctUntilChanged, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AutoUnsubscribe } from '../../../shared/decorators/autoUnsubscribe.decorator';
@Component({
  selector: 'cb-outline-search-field',
  templateUrl: './outline-search-field.component.html',
  styleUrls: ['./outline-search-field.component.scss']
})
@AutoUnsubscribe()
export class OutlineSearchFieldComponent implements OnInit, OnDestroy {
  formControl = new UntypedFormControl('');
  @Output() searchChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() searchClicked: EventEmitter<void> = new EventEmitter<void>();
  @Output() searchLeave: EventEmitter<void> = new EventEmitter<void>();
  private componentDestroyed = new Subject();
  @Input() text: string = '';

  constructor() {
  }
  
  ngOnInit(): void {
    this.formControl.setValue(this.text);
    this.initSearchString();
  }
  private initSearchString() {
    this.formControl
      .valueChanges.pipe(
        takeUntil(this.componentDestroyed),
        tap(text => this.text = text),
        debounceTime(500),
        distinctUntilChanged()
      )
      .subscribe(text => this.searchChanged.emit(text));
  }
  clear(){
    this.formControl.setValue('');
  }
  onSearchClicked(){
    this.searchClicked.emit();
  }
  onBlur(){
    this.searchLeave.emit();
  }
  ngOnDestroy() { }
}
