import { Component, EventEmitter, Input, OnInit, Output, ViewChild, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { ImageItem } from '../../../../../shared/models/survey-items/display-items/imageItem';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SharedService } from '../../../../../infrastructure/services';
import { SurveyDisplayType, SurveysSharedData } from '../../../../../infrastructure/consts/surveys.consts';
import { ContentFileProvider } from '../../../../providers/content-file.provider';
import { transformToCloudfrontUrl } from '../../../../../infrastructure/helpers/cloudfront.helper';
import { first, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AutoUnsubscribe } from '../../../../../shared/decorators/autoUnsubscribe.decorator';
import { ApplicationSettingsProvider } from '../../../../../app-admin/settings/application-settings/application-settings.provider';
import { SurveyEditorValidator } from '../../../../../infrastructure/validators/survey-editor.validators';

@Component({
    selector: 'cb-image-item',
    templateUrl: './image-item.component.html',
    styleUrls: ['./image-item.component.scss']
})
@AutoUnsubscribe()
export class ImageItemComponent implements OnInit, OnDestroy {
    @Input() displayItem: ImageItem;
    @Output() onImageUpload = new EventEmitter<boolean>();
    @ViewChild('fileInput') fileInput;

    image = SurveyDisplayType.IMAGE;
    form: UntypedFormGroup;
    itemPosition: string;
    noImageUrl = transformToCloudfrontUrl('assets/icons/no-image.png');
    file: any;
    fileData: FormData;
    cloneDisplayItem: any;
    isUploading: boolean;
    hasUploadFileSizeError: boolean;
    showFileInput: boolean;
    showCustomCss: boolean;
    private componentDestroyed = new Subject();
    private aspectRatio: number | null = null;

    constructor(
        private cdr: ChangeDetectorRef,
        private fb: UntypedFormBuilder,
        private sharedService: SharedService,
        private contentFileProvider: ContentFileProvider,
        private applicationSettingsProvider: ApplicationSettingsProvider
    ) {
    }

    ngOnInit() {
        this.cloneDisplayItem = { ...this.displayItem };
        this.showFileInput = !(this.cloneDisplayItem.image && this.cloneDisplayItem.image.file_name);
        this.createForm();
        this.createQuestionItemObject();

        this.applicationSettingsProvider
            .getSurveyApplicationSettings()
            .pipe(first())
            .subscribe(data => {
                this.showCustomCss = data.advanced_survey_controls_settings.enable_survey_wide_css;
                this.cdr.detectChanges();
            });
        this.calculateAspectRatio(this.cloneDisplayItem.image_width, this.cloneDisplayItem.image_height);
    }

    itemPositionSelected(value): void {
        this.itemPosition = value;
        this.form.controls['item_position'].setValue(value);
    }

    onUploadImage($event) {
        this.hasUploadFileSizeError = false;
        if (<File>$event.target.files[0]) {
            const file = <File>$event.target.files[0];
            if (file.size < SurveysSharedData.UPLOAD_FILE_MAX_SIZE) {
                this.isUploading = true;
                this.onImageUpload.emit(true);
                this.fileData = new FormData();
                this.fileData.append('image', file, file.name);
                this.contentFileProvider.uploadContentFile(this.fileData).subscribe(
                    img => {
                            this.form.controls['image_id'].setValue(img.id);
                            this.cloneDisplayItem.image = {
                                id: img.id,
                                file_name: img.file_name
                            };
                            this.showFileInput = false;
                            this.isUploading = false;
                            this.onImageUpload.emit(false);
                            this.setImageOriginalDimensions(file);
                        },
                    err => this.handleError(err)
                    );
            } else {
                this.fileData = null;
                this.fileInput.nativeElement.value = null;
                this.hasUploadFileSizeError = true;
            }
        }
    }

    onDimensionChange($event) {
        const controlName = $event.target.getAttribute('formControlName');
        if (this.aspectRatio !== null) {
            switch (controlName) {
                case 'image_width':
                    const height = $event.target.valueAsNumber / this.aspectRatio;
                    this.form.controls['image_height'].setValue(Math.round(height));
                    break;
                case 'image_height':
                    const width = $event.target.valueAsNumber * this.aspectRatio;
                    this.form.controls['image_width'].setValue(Math.round(width));
                    break;
            }
        }
    }

    private createForm(): void {
        this.form = this.fb.group({});
        this.form.addControl(
            'alias',
            new UntypedFormControl(this.cloneDisplayItem.alias)
        );
        this.form.addControl(
            'image_id',
            new UntypedFormControl(
                this.cloneDisplayItem.image
                    ? this.cloneDisplayItem.image.id
                    : ''
            )
        );
        this.form.addControl(
            'file_url',
            new UntypedFormControl(
                this.cloneDisplayItem.image
                    ? this.cloneDisplayItem.image.file_url
                    : ''
            )
        );
        this.form.addControl(
            'html_class',
            new UntypedFormControl(this.cloneDisplayItem.html_class)
        );
        this.form.addControl(
            'alt_text',
            new UntypedFormControl(this.cloneDisplayItem.alt_text)
        );
        this.form.addControl(
            'image_width',
            new UntypedFormControl(this.cloneDisplayItem.image_width)
        );
        this.form.addControl(
            'image_height',
            new UntypedFormControl(this.cloneDisplayItem.image_height)
        );
        this.form.addControl(
            'item_position',
            new UntypedFormControl(this.cloneDisplayItem.item_position)
        );
    }

    private createQuestionItemObject(): void {
        this.updateQuestionItemObject();
        this.form.valueChanges
            .pipe(
                takeUntil(this.componentDestroyed)
            )
            .subscribe(s => {
                this.updateQuestionItemObject();
            });
    }

    private updateQuestionItemObject(): void {
        const control = this.form.controls;
        const questionItem = <ImageItem>{
            item_type: SurveyDisplayType.IMAGE,
            alias: control['alias'].value,
            html_class: control['html_class'].value,
            alt_text: control['alt_text'].value,
            image_width: control['image_width'].value,
            image_height: control['image_height'].value,
            item_position: control['item_position'].value,
            file_url: control['file_url'].value,
            image_id: control['image_id'].value
        };
        this.sharedService.share('questionItemSettings', questionItem);
    }

    private setImageOriginalDimensions(file): void {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            const img = new Image();
            img.src = reader.result as string;
            img.onload = () => {
                this.calculateAspectRatio(img.naturalWidth, img.naturalHeight);
                const width = Math.min(window.innerWidth, img.naturalWidth);
                const height = Math.min(window.innerWidth / this.aspectRatio, img.naturalHeight);
                this.form.controls['image_width'].setValue(Math.round(width));
                this.form.controls['image_height'].setValue(Math.round(height));
            };
        };
    }

    private calculateAspectRatio(width, height) {
        if (typeof width === 'number' && typeof height === 'number') {
            this.aspectRatio = width / height;
        }
    }

    resetFileInput() {
        this.showFileInput = true;
        this.form.controls['image_id'].setValue('');
        this.form.controls['image_width'].setValue(null);
        this.form.controls['image_height'].setValue(null);
        this.cloneDisplayItem.image.id = this.cloneDisplayItem.image.file_name = null;
    }

    handleError(err) {
        this.isUploading = false;
        this.onImageUpload.emit(false);
    }

    public onNumericKeyUp(event: any) {
        return SurveyEditorValidator.validateNumericInput(event);
    }

    ngOnDestroy() {}
}
