import { RankOrderItem } from '../../../../../shared/models/survey-items/question-items/rankOrder';
import { OnInit, Component, Input, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import {
    SurveyQuestionType,
    SurveyRankOrderType
} from '../../../../../infrastructure/consts/surveys.consts';
import { SharedService } from '../../../../../infrastructure/services';
import { SurveyEditorValidator } from '../../../../../infrastructure/validators/survey-editor.validators';
import { Subscription, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { AutoUnsubscribe } from '../../../../../shared/decorators/autoUnsubscribe.decorator';
import { MultipleChoicesItemComponent } from '../multiple-choices-item.component';

@Component({
    selector: 'cb-rank-order-item',
    templateUrl: './rank-order-item.component.html',
    styleUrls: ['./rank-order-item.component.scss']
})
@AutoUnsubscribe()
export class RankOrderComponent extends MultipleChoicesItemComponent implements OnInit, OnDestroy {
    @Input() questionItem: RankOrderItem;
    form: UntypedFormGroup;
    rankOrder = SurveyQuestionType.RANKORDER;
    rankOrderTypes = SurveyRankOrderType;
    rankOrderType: string;
    itemPosition: string;
    textPosition: string;
    isDropDown = false;
    listSubscription: Subscription;
    filledOptionsLength: number;
    private componentDestroyed = new Subject();

    constructor(
        private fb: UntypedFormBuilder,
        private sharedService: SharedService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        this.createFormGroup();
        this.subscribeToFormUpdates();
        this.updateQuestionItemObject();
        this.listSubscription = this.sharedService.getData('multipleOptions').subscribe(list => {
            const listLength = list.data.options.length;
            this.filledOptionsLength = 0;
            for (let i = 0; listLength > i; i++) {
                if (list.data.options[i].text) {
                    this.filledOptionsLength++;
                }
            }
            this.form.controls['max_to_rank'].setValidators([Validators.max(this.filledOptionsLength), Validators.min(1)]);
            this.form.controls['min_to_rank'].setValidators([Validators.max(this.filledOptionsLength)]);
        });
        this.isDropDown =
            this.questionItem.rank_order_type === this.rankOrderTypes.TOP_N;
        this.rankOrderType = this.questionItem.rank_order_type
            ? this.questionItem.rank_order_type
            : this.rankOrderTypes.SELECTABLE_DRAGN_DROP;
    }

    onItemPositionSelected(value): void {
        this.itemPosition = value;
        this.form.controls['item_position'].setValue(value);
    }

    onLabelPositionSelected(value): void {
        this.textPosition = value;
        this.form.controls['question_text_position'].setValue(value);
    }

    private createFormGroup(): void {
        let isAllQuestionsRequired = false;
        if (!this.questionItem) {
            this.questionItem = <RankOrderItem>{};
        }
        if (!this.questionItem.id) {
            isAllQuestionsRequired = this.sharedService.surveyRespondentSettings.getValue() ?
            this.sharedService.surveyRespondentSettings.getValue().makeQuestionsRequired : false;
        }
        this.form = this.fb.group({
            // behavior
            alias: new UntypedFormControl(this.questionItem.alias),
            html_class: new UntypedFormControl(this.questionItem.html_class),
            is_required: new UntypedFormControl(this.questionItem.is_required || isAllQuestionsRequired),
            is_soft_required: new UntypedFormControl(this.questionItem.softRequiredEnabled ? !!this.questionItem.is_soft_required : false),
            randomize: new UntypedFormControl(!!this.questionItem.randomize),
            rank_order_type: new UntypedFormControl(
                this.questionItem.rank_order_type ||
                this.rankOrderTypes.SELECTABLE_DRAGN_DROP
            ),
            option_type: new UntypedFormControl(
                this.questionItem.option_type || 'Text'
            ),
            max_to_rank: new UntypedFormControl(
                this.questionItem.max_to_rank
            ),
            min_to_rank: new UntypedFormControl(
                this.questionItem.min_to_rank ? this.questionItem.min_to_rank :
                        isAllQuestionsRequired ? 1 : null
            ),
            // appearance
            question_text_position: new UntypedFormControl(
                this.questionItem.question_text_position
            ),
            item_position: new UntypedFormControl(this.questionItem.item_position)
        });
        this.itemPosition = this.questionItem.item_position;
        this.textPosition = this.questionItem.question_text_position;
    }

    private subscribeToFormUpdates(): void {
        this.form.valueChanges.pipe(
            takeUntil(this.componentDestroyed),
            debounceTime(500),
            distinctUntilChanged()
        ).subscribe(s => {
            this.updateQuestionItemObject();
        });
    }

    private updateQuestionItemObject() {
        if (this.form.controls['rank_order_type'].value === this.rankOrderTypes.DRAGN_DROPPABLE
            && (this.form.controls['min_to_rank'].value || this.form.controls['max_to_rank'].value)) {
            this.form.controls['min_to_rank'].setValue(null);
            this.form.controls['max_to_rank'].setValue(null);
        }
        const control = this.form.controls;
        const questionItem = new RankOrderItem();
        questionItem.item_type = SurveyQuestionType.RANKORDER;
        questionItem.alias = control['alias'].value;
        questionItem.html_class = control['html_class'].value;
        questionItem.randomize = control['randomize'].value;
        questionItem.rank_order_type = control['rank_order_type'].value;
        questionItem.option_type = control['option_type'].value;
        questionItem.is_required = control['is_required'].value;
        questionItem.is_soft_required = control['is_soft_required'].value;
        questionItem.min_to_rank = control['min_to_rank'].value;
        questionItem.max_to_rank = control['max_to_rank'].value;
        questionItem.question_text_position =
            control['question_text_position'].value;
        questionItem.item_position = control['item_position'].value;
        this.sharedService.share('questionItemSettings', questionItem);
        this.rankOrderType = questionItem.rank_order_type;
        this.changeDetectorRef.detectChanges();
    }

    public onNumericKeyUp(event: any) {
        return SurveyEditorValidator.validateNumericInput(event);
    }

    public changeType(ev) {
        this.questionItem.option_type = ev.value;
    }

    checkRankType(ev) {
        if (ev.value === this.rankOrderTypes.TOP_N) {
            this.questionItem.option_type = 'Text';
            this.form.controls['option_type'].setValue('Text');
            this.isDropDown = true;
        } else {
            this.isDropDown = false;
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();
        this.listSubscription.unsubscribe();
    }
}
