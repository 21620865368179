<form [formGroup]="form" class="cb-group-questions-form">
    <ng-container formArrayName="branching_rules">
        <div
            *ngFor="
                let rule of branchingRulesExpressions;
                let ruleIndex = index
            "
            [formGroupName]="ruleIndex"
        >
            <div class="main-field-container" *ngIf="isPageBranchingRules">
                <div>{{ 'GROUP-EXPRESSIONS.SKIP' | translate }}</div>
                <mat-form-field>
                    <mat-select
                        [value]="selectedPageId"
                        [formControlName]="'go_to_page_id'"
                    >
                        <mat-option
                            *ngFor="let item of pageTitles"
                            [value]="item.key"
                            >{{ 'SHARED.PAGE' | translate }} :
                            {{ item.title !== '' ? item.title : item.number }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div formArrayName="groups" class="cb-logic-group-container">
                <div
                    *ngFor="
                        let group of rule.get('groups').controls;
                        let groupIndex = index
                    "
                    [formGroupName]="groupIndex"
                >
                    <div class="inner-item-card" formArrayName="expressions">
                        <div
                            class="cb-logic-group"
                            *ngFor="
                                let expression of group.get('expressions')
                                    .controls;
                                let expressionIndex = index
                            "
                            [formGroupName]="expressionIndex"
                        >
                            <div
                                class="groups-header-row group-fields-container"
                                *ngIf="
                                    expressionIndex === 0 &&
                                    groupIndex === 0 &&
                                    isQuestionDefined(
                                        ruleIndex,
                                        groupIndex,
                                        expressionIndex
                                    ) &&
                                    isOperatorDefined(
                                        ruleIndex,
                                        groupIndex,
                                        expressionIndex
                                    )
                                "
                            >
                                <div class="control-field">
                                    {{ 'GROUP-EXPRESSIONS.IF' | translate }}
                                </div>
                                <div class="control-field"></div>
                                <div class="state-capture control-field">
                                    {{ 'GROUP-EXPRESSIONS.STATE' | translate }}
                                </div>
                                <div class="value-capture control-field">
                                    {{ 'GROUP-EXPRESSIONS.VALUE' | translate }}
                                </div>
                            </div>
                            <div>
                                <div class="group-fields-container">
                                    <mat-form-field class="control-field">
                                        <mat-select
                                            [value]="defaultSourceType"
                                            [formControlName]="'source'"
                                            (selectionChange)="
                                                onSourceTypeChanged(
                                                    $event,
                                                    ruleIndex,
                                                    groupIndex,
                                                    expressionIndex
                                                )
                                            "
                                        >
                                            <mat-option value="Default">{{
                                                'GROUP-EXPRESSIONS.SOURCE-TYPE'
                                                    | translate
                                            }}</mat-option>
                                            <mat-option value="SurveyItem">{{
                                                'GROUP-EXPRESSIONS.QUESTION'
                                                    | translate
                                            }}</mat-option>
                                            <mat-option
                                                value="ProfileProperty"
                                                >{{
                                                    'GROUP-EXPRESSIONS.CONTACT-ATTRIBUTE'
                                                        | translate
                                                }}</mat-option
                                            >
                                            <mat-option
                                                value="ResponseProperty"
                                                >{{
                                                    'GROUP-EXPRESSIONS.RESPONSE-PROPERTY'
                                                        | translate
                                                }}</mat-option
                                            >
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field
                                        class="control-field"
                                        *ngIf="
                                            isSourceTypeDefined(
                                                ruleIndex,
                                                groupIndex,
                                                expressionIndex
                                            )
                                        "
                                    >
                                        <mat-select
                                            [value]="defaultSourceType"
                                            [formControlName]="'question'"
                                            (selectionChange)="
                                                onQuestionChanged(
                                                    $event,
                                                    ruleIndex,
                                                    groupIndex,
                                                    expressionIndex
                                                )
                                            "
                                        >
                                            <mat-option value="Default">{{
                                                'GROUP-EXPRESSIONS.SOURCE'
                                                    | translate
                                            }}</mat-option>
                                            <mat-option
                                                *ngFor="
                                                    let question of getFormControlValue(
                                                        'questions_list',
                                                        ruleIndex,
                                                        groupIndex,
                                                        expressionIndex
                                                    )
                                                "
                                                [value]="question.key"
                                                >{{ question.name }}</mat-option
                                            >
                                        </mat-select>
                                    </mat-form-field>
                                    <mat-form-field
                                        class="control-field"
                                        *ngIf="
                                            isQuestionDefined(
                                                ruleIndex,
                                                groupIndex,
                                                expressionIndex
                                            )
                                        "
                                    >
                                        <mat-select
                                            [value]="defaultSourceType"
                                            [formControlName]="'operator'"
                                            (selectionChange)="
                                                onOperatorChanged(
                                                    $event,
                                                    ruleIndex,
                                                    groupIndex,
                                                    expressionIndex
                                                )
                                            "
                                        >
                                            <!-- <mat-option value="Default">{{'GROUP-EXPRESSIONS.COMPARISON' | translate}}</mat-option> -->
                                            <mat-option
                                                *ngFor="
                                                    let operator of operators
                                                        | async
                                                "
                                                [value]="operator"
                                                >{{
                                                    'EXPRESSIONS-OPERATORS.' +
                                                        operator | translate
                                                }}</mat-option
                                            >
                                        </mat-select>
                                    </mat-form-field>
                                    <span
                                        class="control-field"
                                        *ngIf="
                                            isOperatorDefined(
                                                ruleIndex,
                                                groupIndex,
                                                expressionIndex
                                            ) &&
                                            !isAnsweredComparisonType(
                                                ruleIndex,
                                                groupIndex,
                                                expressionIndex
                                            )
                                        "
                                    >
                                        <mat-form-field
                                            *ngIf="
                                                getFormControlValue(
                                                    'is_value_type_text',
                                                    ruleIndex,
                                                    groupIndex,
                                                    expressionIndex
                                                )
                                            "
                                        >
                                            <input
                                                matInput
                                                formControlName="value"
                                            />
                                        </mat-form-field>
                                        <cb-date-time-picker
                                            class="picker"
                                            formControlName="value"
                                            *ngIf="
                                                getFormControlValue(
                                                    'is_value_type_date',
                                                    ruleIndex,
                                                    groupIndex,
                                                    expressionIndex
                                                )
                                            "
                                            [datepickerMode]="
                                                DatepickerMode.DATE
                                            "
                                        ></cb-date-time-picker>
                                        <mat-form-field
                                            *ngIf="
                                                getFormControlValue(
                                                    'is_value_type_choices',
                                                    ruleIndex,
                                                    groupIndex,
                                                    expressionIndex
                                                )
                                            "
                                            [floatLabel]="'never'"
                                        >
                                            <mat-label>{{
                                                    'GROUP-EXPRESSIONS.VALUE'
                                                        | translate
                                                }}</mat-label>
                                            <mat-select
                                                [value]="defaultSourceType"
                                                [formControlName]="'value'"
                                            >
                                                <mat-option
                                                    *ngFor="
                                                        let choice of getFormControlValue(
                                                            'expression_choices',
                                                            ruleIndex,
                                                            groupIndex,
                                                            expressionIndex
                                                        )
                                                    "
                                                    [value]="choice.id"
                                                    [innerHtml]="choice.text | keepHtml"
                                                    ></mat-option
                                                >
                                            </mat-select>
                                        </mat-form-field>
                                    </span>

                                    <span
                                        *ngIf="
                                            isOperatorDefined(
                                                ruleIndex,
                                                groupIndex,
                                                expressionIndex
                                            )
                                        "
                                    >
                                        <button
                                            mat-icon-button
                                            (click)="
                                                removeExpression(
                                                    ruleIndex,
                                                    groupIndex,
                                                    expressionIndex
                                                )
                                            "
                                            matTooltip="Delete"
                                        >
                                            <cb-svg-icon
                                                class="icon"
                                                [name]="'close'"
                                            ></cb-svg-icon>
                                        </button>
                                        <span
                                            *ngIf="
                                                expressionIndex !==
                                                getExpressionsLength(group) - 1
                                            "
                                        >
                                            <span class="logical-expression">{{
                                                'GROUP-EXPRESSIONS.AND'
                                                    | translate
                                            }}</span>
                                        </span>
                                        <span
                                            *ngIf="
                                                expressionIndex ===
                                                getExpressionsLength(group) - 1
                                            "
                                        >
                                            <a
                                                class="cb-button-link"
                                                (click)="
                                                    addExpression(
                                                        ruleIndex,
                                                        groupIndex
                                                    )
                                                "
                                            >
                                                <i class="fa fa-plus"></i>
                                                &nbsp;
                                                {{
                                                    'GROUP-EXPRESSIONS.ADD-AND-CONDITION'
                                                        | translate
                                                }}
                                            </a>
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <span
                        *ngIf="
                            groupIndex < rule.get('groups').controls.length - 1
                        "
                        class="logical-expression"
                    >
                        {{ 'GROUP-EXPRESSIONS.OR' | translate }}
                    </span>
                    <div
                        *ngIf="
                            groupIndex ===
                            rule.get('groups').controls.length - 1
                        "
                    >
                        <a
                            class="cb-button-link"
                            (click)="addGroup(ruleIndex, groupIndex + 1, true)"
                        >
                            <i class="fa fa-plus"></i> &nbsp;
                            {{
                                'GROUP-EXPRESSIONS.ADD-OR-CONDITION' | translate
                            }}
                        </a>
                    </div>
                    <div
                        *ngIf="
                            isPageBranchingRules &&
                            ruleIndex ===
                                branchingRulesExpressions.length - 1 &&
                            groupIndex ===
                                rule.get('groups').controls.length - 1
                        "
                    >
                        <a
                            class="cb-button-link"
                            (click)="
                                addBranchingRule(
                                    selectedPageId,
                                    ruleIndex + 1,
                                    groupIndex,
                                    true
                                )
                            "
                        >
                            <i class="fa fa-plus"></i> &nbsp;
                            {{ 'GROUP-EXPRESSIONS.ADD-BRANCHING' | translate }}
                        </a>
                    </div>
                </div>
            </div>
            <mat-divider *ngIf="isPageBranchingRules"></mat-divider>
            <br />
        </div>
    </ng-container>
</form>
