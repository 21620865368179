import { mergeMap } from 'rxjs/operators';
import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { StorageProvider } from '../providers';
import { Identity } from '../models';
import { SkipInterceptor } from '../consts/interceptors.const';

Injectable();
export class JwtInterceptor implements HttpInterceptor {
    constructor(private storageProvider: StorageProvider) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        const bypassAuth = req.headers.has(SkipInterceptor.JWT);
        let headers = req.headers;

        if (bypassAuth) {
            headers = headers.delete(SkipInterceptor.JWT);
            return next.handle(req.clone({ headers }));
        }

        return this.storageProvider.getIdentity().pipe(
            mergeMap((identityStr: string) => {
                const identity: Identity = JSON.parse(identityStr);
                if (identity && identity.access_token) {
                    headers = headers.set('Authorization', `Bearer ${identity.access_token}`);
                }

                headers = headers
                    .set('Cache-Control', 'no-cache')
                    .set('Pragma', 'no-cache')
                    .set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');

                return next.handle(req.clone({ headers }));
            })
        );
    }
}
