<mat-form-field class="outline-search-field no-label no-hint" appearance="outline" floatLabel="never">
    <mat-label>{{ 'SURVEYS-LIST.SEARCH' | translate }}</mat-label>
  <input matInput autocomplete="off" [formControl]="formControl" (blur)="onBlur()" autofocus/>
  <button *ngIf="!text" mat-icon-button matSuffix (click)="onSearchClicked()">
    <mat-icon svgIcon ="fi_search"></mat-icon>
  </button>
  <button *ngIf="text" mat-icon-button matSuffix (click)="clear()">
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>
