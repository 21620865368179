<div class="cb-question-item-wrapper-outer {{ this.questionItem.html_class }}">
    <div [ngClass]="layoutClass">
        <cb-questions-header
                [questionItem]="questionItem"
                [hideSubtext]="isMatrix"
        ></cb-questions-header>
        <form
            [formGroup]="form"
            class="cb-question-item-content"
            [ngClass]="!isMatrix ? 'cb-question-item-content--max-width' : ''"
        >
            <mat-radio-group
                role="group" [attr.aria-labelledby]="questionId + questionItem.id"
                formControlName="value"
                class="mat-radio-button-group with-columns"
                [ngClass]="isMatrix ? 'd-block' : ''"
                [ngStyle]="styleObj"
            >
                <ng-container *ngIf="!isMatrix">
                    <mat-radio-button
                        *ngFor="
                            let c of enabled_regular_choices;
                            index as i
                        "
                        [checked]="c.is_default"
                        [value]="c.id"
                        [aria-label]="c.text | extractInnerText"
                        [ngStyle]="cellStyles[i]"
                    >
                        <span *ngIf="questionItem.show_number_labels">{{ i + 1 }}.&nbsp;</span>
                        <span [innerHtml]="c.text | keepHtml"></span>
                    </mat-radio-button>
                    <span
                        [ngStyle]="cellStyles[enabled_regular_choices.length]"
                        *ngIf="questionItem.allow_other"
                        class="cb-other-answer choice-item-container"
                    >
                        <mat-radio-button
                            [value]="otherChoice.id"
                            aria-label="other"
                            [checked]="otherChoice.is_default"
                            #otherOption
                        >
                            <span *ngIf="questionItem.show_number_labels">{{ enabled_regular_choices.length + 1 }}.&nbsp;</span>
                            <span [innerHtml]="otherChoice.text | keepHtml"></span>
                        </mat-radio-button>
                        <span *ngIf="otherOption.checked">
                            <mat-form-field>
                                <mat-label>{{surveyText.YourOptionPrompt}}</mat-label>
                                <input
                                    matInput
                                    formControlName="other_value"
                                />
                            </mat-form-field>
                        </span>
                    </span>
                </ng-container>


                <div
                    *ngIf="isMatrix"
                    class="table-matrix-group"
                >
                    <div *ngFor="let c of enabled_regular_choices" [ngStyle]="radiobuttonDivStyles"
                         class="mat-button-container" [ngClass]="isMobile || (isTablet && isMatrix) ? 'mat-button-flex' : 'mat-button-inline-block'">
                        <mat-radio-button
                                [checked]="c.is_default"
                                [value]="c.id"
                                [aria-label]="c.text | extractInnerText"
                        >
                            <span [innerHtml]="c.text | keepHtml"></span>
                        </mat-radio-button>
                    </div>

                    <div *ngIf="questionItem.allow_other">
                        <mat-radio-button [value]="otherChoice.id" #otherOption aria-label="other">
                            <span [innerHtml]="otherChoice.text | keepHtml"></span>
                        </mat-radio-button>
                        <span *ngIf="otherOption.checked">
                            <mat-form-field>
                                <mat-label>{{surveyText.YourOptionPrompt}}</mat-label>
                                <input
                                    matInput
                                    formControlName="other_value"
                                />
                            </mat-form-field>
                        </span>
                    </div>
                </div>
            </mat-radio-group>
        </form>
    </div>
</div>
<cb-server-validation-errors
    [item]="questionItem"
></cb-server-validation-errors>
