import { Component, EventEmitter, Input, OnInit, Output, OnDestroy, ElementRef, HostListener, AfterViewInit } from '@angular/core';
import { RatingScaleItem } from '../../../models/survey-items/question-items/ratingScaleItem';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { getQuestionItemInnerCssClass } from '../../../../infrastructure/helpers/surveys.helper';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AutoUnsubscribe } from '../../../decorators/autoUnsubscribe.decorator';
import { prepareHorizontalRatingScaleStyle } from '../../../../infrastructure/helpers/column-style.helper';
import { WINDOW_WIDTH } from '../../../../infrastructure/consts/window-width';
import { TakeSurveyData } from '../../../../infrastructure/consts/take-survey.consts';
import { PrintService } from '../../../../infrastructure/services';

@Component({
    selector: 'cb-rating-scale-item-preview',
    templateUrl: './rating-scale-item-preview.component.html',
    styleUrls: ['./rating-scale-item-preview.component.scss']
})
@AutoUnsubscribe()
export class RatingScalePreviewItemComponent implements AfterViewInit, OnInit, OnDestroy {
    @Input() questionItem: RatingScaleItem;
    @Input() isMatrix: boolean;
    @Input() isPrint: boolean;
    @Output() updated = new EventEmitter<any>();

    form: UntypedFormGroup;

    getQuestionItemInnerCssClass = getQuestionItemInnerCssClass;
    prepareHorizontalRatingScaleStyle = prepareHorizontalRatingScaleStyle;
    showText: boolean;
    showPoints: boolean;
    showPointsOnly: boolean;
    textItemWidth: Object;
    middleTextItemWidth: Object;
    appendEmptyLabel: boolean;
    isMobile: boolean;
    isTablet: boolean;
    defaultLayout: string;
    layoutClass: string;
    rbGroupStyle: Object;
    rbStyles: Object = [];
    notApplicable = 'Not applicable';
    private componentDestroyed = new Subject();
    questionId = TakeSurveyData.QUESTION_ID;

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.checkLayout();
    }

    constructor(
        private printService: PrintService,
        private elem: ElementRef
        ) {}

    ngOnInit() {
        this.prepareUiData();
        this.createFormGroup();
    }

    ngAfterViewInit(): void {
        if (!this.isMatrix) {
            this.printService.waitForImageLoadingAndMarkReady(this.questionItem.id, this.elem);
        }
    }

    prepareUiData() {
        this.defaultLayout = this.questionItem.layout;
        this.layoutClass = this.getQuestionItemInnerCssClass(this.questionItem);
        this.checkLayout();

        this.showText = this.questionItem.choice_label_type === 'Text' || this.questionItem.choice_label_type === 'TextAndRating';
        this.showPoints = this.questionItem.choice_label_type === 'Rating' || this.questionItem.choice_label_type === 'TextAndRating';
        this.showPointsOnly = this.questionItem.choice_label_type === 'Rating';
    }

    private createFormGroup(): void {
        this.form = new UntypedFormGroup({
            id: new UntypedFormControl(this.questionItem.id),
            choice_id: new UntypedFormControl(this.questionItem.choice_id)
        });
        this.form.valueChanges
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(s => this.updated.emit(s));
        if(this.isMatrix && this.questionItem.choice_id){
            this.updated.emit(this.form.value);
        }
    }

    public resetForm() {
        this.form.get('choice_id').reset();
    }

    checkLayout() {
        this.isMobile = window.innerWidth < WINDOW_WIDTH.mobileWidth;
        this.isTablet = window.innerWidth < WINDOW_WIDTH.tabletWidth;
        if (this.isMobile || this.isMatrix && this.isTablet) {
            this.questionItem.layout = 'Vertical';
            this.layoutClass = this.getQuestionItemInnerCssClass({
                'layout': 'Vertical',
                'question_text_position': 'top',
                'item_position': 'left',
            });
            this.textItemWidth = {};
            this.appendEmptyLabel = false;
            this.middleTextItemWidth = {};
        } else {
            this.questionItem.layout = this.defaultLayout;
            this.layoutClass = this.getQuestionItemInnerCssClass(this.questionItem);
        }
        if (this.questionItem.layout === 'Horizontal') {
            this.textItemWidth = {width: 100 / this.questionItem.choices.length + '%'};
            this.appendEmptyLabel = this.questionItem.enable_not_applicable;
            this.middleTextItemWidth = {
                width: this.appendEmptyLabel
                    ? 100 - 100 / this.questionItem.choices.length * 3 + '%'
                    : 100 - 100 / this.questionItem.choices.length * 2 + '%'};
            [this.rbGroupStyle, this.rbStyles] = this.prepareHorizontalRatingScaleStyle(this.questionItem);
        }
    }

    ngOnDestroy() {}
}
