<form [formGroup]="form" autocomplete="off">
    <cb-question-item-title
        title="{{ 'SURVEY-EDITOR.SHARED.DISPLAY_ITEM' | translate }}"
    ></cb-question-item-title>
    <div class="cb-change-type-section">
        <cb-item-type [itemType]="image"></cb-item-type>
    </div>
    <div class="form-section">
        <div class="form-fields-group">
            <div style="display: none">
                <mat-form-field>
                    <input matInput type="text" formControlName="image_id" />
                    <input
                        matInput
                        type="text"
                        formControlName="file_url"
                        #imageUrl
                    />
                </mat-form-field>
            </div>
        </div>
        <input
            *ngIf="showFileInput"
            type="file"
            (change)="onUploadImage($event)"
            #fileInput
            accept="image/*"
            [disabled]="isUploading"
        />
        <p
            *ngIf="!showFileInput"
            [innerHTML]="
            cloneDisplayItem.image ? (cloneDisplayItem.image.file_name | keepHtml) : ''
            "
        ></p>
        <input
            *ngIf="!showFileInput"
            type="button"
            value="Clear"
            (click)="resetFileInput()"
        />
        <mat-error *ngIf="hasUploadFileSizeError">
            {{
            'SURVEY-EDITOR.ITEMS.SLIDER.UPLOAD-FILE-SIZE-ERROR' | translate
            }}
        </mat-error>
        <cb-spinner [show]="isUploading"></cb-spinner>
        <div class="form-fields-group">
            <mat-form-field class="cb-form-field">
                <mat-label>{{
                        'SURVEY-EDITOR.SHARED.CHOICE.ALIAS' | translate
                    }}</mat-label>
                <input
                    matInput
                    formControlName="alias"
                />
            </mat-form-field>
            <mat-form-field class="cb-form-field" *ngIf="showCustomCss">
                <mat-label>{{
                        'SURVEY-EDITOR.SHARED.CSS-CLASS' | translate
                    }}</mat-label>
                <input
                    matInput
                    formControlName="html_class"
                />
            </mat-form-field>
            <mat-form-field class="cb-form-field">
                <mat-label>{{
                        'SURVEY-EDITOR.SHARED.ALT-TEXT' | translate
                    }}</mat-label>
                <input
                    matInput
                    formControlName="alt_text"
                />
            </mat-form-field>
        </div>
    </div>
    <div class="form-section">
        <h3 class="form-section-title">
            {{ 'SURVEY-EDITOR.SHARED.APPEARANCE.APPEARANCE' | translate }}
        </h3>
        <div class="form-fields-group">
            <mat-form-field>
                <mat-label>{{
                        'SURVEY-EDITOR.SHARED.APPEARANCE.IMAGE_WIDTH'
                            | translate
                    }}</mat-label>
                <input
                    matInput
                    type="number"
                    autocomplete="off"
                    (keypress)="onNumericKeyUp($event)"
                    (change)="onDimensionChange($event)"
                    formControlName="image_width"
                />
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{
                        'SURVEY-EDITOR.SHARED.APPEARANCE.IMAGE_HEIGHT'
                            | translate
                    }}</mat-label>
                <input
                    matInput
                    type="number"
                    autocomplete="off"
                    (keypress)="onNumericKeyUp($event)"
                    (change)="onDimensionChange($event)"
                    formControlName="image_height"
                />
            </mat-form-field>
        </div>
        <div class="form-group-position">
            <cb-item-position
                class="position-control-group"
                [selectedOption]="itemPosition"
                (selected)="itemPositionSelected($event)"
            ></cb-item-position>
        </div>
    </div>
    <div class="form-section">
        <h3 class="form-section-title">
            {{ 'SURVEY-EDITOR.SHARED.CONDITIONS' | translate }}
            <cb-help-icon [tooltip]="'SURVEY-EDITOR.SHARED.TOOLTIPS.CONDITIONS' | translate"></cb-help-icon>
        </h3>
        <cb-groups-expressions-element
            [source]="
                displayItem.include_condition ? displayItem : cloneDisplayItem
            "
        ></cb-groups-expressions-element>
    </div>
</form>
