<div>
  <h2>
    {{ 'SURVEYS-LIST.ALL_SURVEYS' | translate }}
  </h2>
  <span *ngIf="isEditAllowed">
    <cb-button icon="fi_archive" type="icon" [disabled]="!bulkActions" [matTooltip]="'SURVEYS-LIST.ARCHIVE' | translate" (onClick)="onArchiveSelectedSurveys()"></cb-button>
    <cb-button icon="fi_folder_upload" type="icon" [disabled]="!bulkActions" [matTooltip]="'SURVEYS-LIST.MOVE-TO-FOLDER' | translate" (onClick)="onMoveSelectedSurveysToFolder()"></cb-button>
    <cb-button icon="fi_trash" type="icon" [disabled]="!bulkActions" [matTooltip]="'SURVEYS-LIST.DELETE' | translate" (onClick)="onDeleteSelectedSurveys()"></cb-button>
    <cb-outline-search-field *ngIf="displaySearchField" [text]="surveyOptions.searchText"
      (searchChanged)="onSearchChanged($event)" (searchClicked)="onSearchLeave()" (searchLeave)="onSearchLeave()"></cb-outline-search-field>
    <cb-button *ngIf="!displaySearchField" icon="fi_search" type="stroked"
      (onClick)="displaySearchFieldOnClick()"></cb-button>
    <cb-button icon="fi_funnel" type="stroked" [matMenuTriggerFor]="appMenu"></cb-button>
    <cb-button [text]="'SURVEYS-LIST.NEW_FOLDER'|translate" icon="fi_plus" type="stroked"
      (onClick)="createFolder()"></cb-button>
    <cb-button [text]="'SURVEYS-LIST.NEW_SURVEY_FROM_FILE'|translate" icon="import" type="stroked"
      (onClick)="createSurveyFromFile()"></cb-button>
    <cb-button color="primary" [text]="'SURVEYS-LIST.NEW_SURVEY'|translate" icon="fi_plus" type="flat"
      (onClick)="createSurvey()"></cb-button>
  </span>
</div>
<mat-menu #appMenu="matMenu" overlapTrigger="false" xPosition="before" class="mat-elevation-z8 round-10">
  <cb-surveylist-filter [surveyOptions]="surveyOptions" (apply)="onFilterClick()" (reset)="onResetClick()"></cb-surveylist-filter>
</mat-menu>
