import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomMaterialModule } from '../modules/material-components.module';
import { SharedModule } from '../modules/shared.module';
import { LazyTranslateLoaderModule } from '../../app-admin/entry-point/lazy-translate-loader.module';
import { EditMyInfoComponent } from './edit-my-info/edit-my-info.component';

const declarations = [
    EditMyInfoComponent
];
const entryComponents = [
    EditMyInfoComponent
];

@NgModule({
    imports: [
        // system
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        // translation
        LazyTranslateLoaderModule,
        // custom
        CustomMaterialModule,
        SharedModule
    ],
    declarations: declarations,
    exports: [...declarations, ...entryComponents]
})
export class ContactsSharedModule {}
