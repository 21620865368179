import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPermissionsModule } from 'ngx-permissions';
import { CustomMaterialModule } from '../modules/material-components.module';
import { SharedModule } from '../modules/shared.module';
import { SurveyItemStatusComponent } from './survey-item-status/survey-item-status.component';
import { PageEditToolbarComponent } from './page-edit-toolbar/page-edit-toolbar.component';
import { MenuDialogComponent } from './menu-dialog/menu-dialog.component';
import { ItemTypeComponent } from './item-type/item-type.component';
import { InlineDialogComponent } from './inline-dialog/inline-dialog';
import { DynamicFactoryComponent } from './dynamic-factory/dynamic-factory.component';
import { Bar100Component } from './bar100/bar100.component';
import { MergeTextListComponent } from './merge-text-list/merge-text-list.component';
import { LazyTranslateLoaderModule } from '../../app-admin/entry-point/lazy-translate-loader.module';
import * as infra from '../../infrastructure';

const declarations = [
    SurveyItemStatusComponent,
    PageEditToolbarComponent,
    MenuDialogComponent,
    ItemTypeComponent,
    InlineDialogComponent,
    DynamicFactoryComponent,
    Bar100Component,
    MergeTextListComponent,
];
const entryComponents = [
    MenuDialogComponent,
    MergeTextListComponent,
];

@NgModule({
    imports: [
        // system
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        // permissions
        NgxPermissionsModule.forRoot(),
        // translation
        LazyTranslateLoaderModule,
        // custom
        CustomMaterialModule,
        SharedModule
    ],
    declarations: declarations,
    exports: [...declarations, ...entryComponents],
    providers: [...infra.providers]
})
export class SurveysSharedModule {}
