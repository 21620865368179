import {
    Component,
    EventEmitter,
    Input,
    Output,
    OnInit,
    ViewChild,
    ElementRef,
    ChangeDetectionStrategy,
    OnDestroy
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { DictionaryElement } from '../../../infrastructure/models/dictionary.model';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { AutoUnsubscribe } from '../../../shared/decorators/autoUnsubscribe.decorator';

@Component({
    selector: 'cb-infinite-list',
    templateUrl: './infiniteList.component.html',
    styleUrls: ['./infiniteList.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class InfiniteListComponent implements OnInit, OnDestroy {
    @Input() title: string;
    @Input() isLoading: boolean;
    @Input() nameOfSearchSubject: string;
    @Input() allowRemove: boolean;
    @Input() allowMore: boolean;
    @Input() items: DictionaryElement[];
    @Output() removeElement: EventEmitter<
        DictionaryElement
    > = new EventEmitter();
    @Output() elementClicked: EventEmitter<
        DictionaryElement
    > = new EventEmitter();
    @Output() searchChanged: EventEmitter<string> = new EventEmitter();
    @Output() more: EventEmitter<string> = new EventEmitter();
    @ViewChild('s') set searchElement(elRef: ElementRef) {
        if (elRef && elRef.nativeElement && this.searchFocused) {
            elRef.nativeElement.focus();
        }
    }

    public searchFocused: boolean;
    public form: UntypedFormGroup;
    private readonly delayTime = 500;
    private componentDestroyed = new Subject();

    constructor(private fb: UntypedFormBuilder) {
        _.bindAll(this, ['searchChangeAction', 'onRemoveClick', 'itemClicked']);
    }

    ngOnInit() {
        this.form = this.fb.group({
            searchString: new UntypedFormControl('')
        });
        this.initSearchString();
    }

    private initSearchString() {
        this.form
            .get('searchString')
            .valueChanges.pipe(
            takeUntil(this.componentDestroyed),
            debounceTime(this.delayTime),
            distinctUntilChanged()
        )
            .subscribe(this.searchChangeAction);
    }

    private searchChangeAction(queryString) {
        this.searchChanged.emit(queryString);
    }

    itemClicked(i) {
        if (i || i === 0) {
            this.elementClicked.emit(this.items[i]);
        }
    }

    onRemoveClick(i) {
        if (i || i === 0) {
            this.removeElement.emit(this.items[i]);
        }
    }

    getMore() {
        this.more.emit();
    }

    searchFocus() {
        this.searchFocused = true;
    }

    searchBlur() {
        this.searchFocused = false;
    }

    ngOnDestroy() {}
}
