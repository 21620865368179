import { ItemOption } from '../../../models/survey-items/question-items/itemOption';
import { RadiobuttonsItem } from '../../../models/survey-items/question-items/radiobuttonsItem';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import * as _ from 'lodash';
import { SurveyLayoutType } from '../../../../infrastructure/consts/surveys.consts';
import { getEnabledChoices, getEnabledRegularChoices, getQuestionItemInnerCssClass } from '../../../../infrastructure/helpers/surveys.helper';
import { prepareStyle } from '../../../../infrastructure/helpers/column-style.helper';
import { Component, Input, OnInit, Output, EventEmitter, OnDestroy, ChangeDetectorRef, HostListener, ElementRef, AfterViewInit } from '@angular/core';
import { SurveyDefaultText } from '../../../../infrastructure/consts/surveyDefaultText';
import { SharedService } from '../../../../infrastructure/services/shared.service';
import { Subscription, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AutoUnsubscribe } from '../../../decorators/autoUnsubscribe.decorator';
import { WINDOW_WIDTH } from '../../../../infrastructure/consts/window-width';
import { TakeSurveyData } from '../../../../infrastructure/consts/take-survey.consts';
import { PrintService } from '../../../../infrastructure/services';

@Component({
    selector: 'cb-radiobuttons-item-preview',
    templateUrl: './radiobuttons-item-preview.component.html',
    styleUrls: ['./radiobuttons-item-preview.component.scss']
})
@AutoUnsubscribe()
export class RadioButtonsPreviewItemComponent implements AfterViewInit, OnInit, OnDestroy {
    @Input() questionItem: RadiobuttonsItem;
    @Input() isMatrix: boolean;
    @Output() updated = new EventEmitter<any>();
    surveyText = SurveyDefaultText;
    textSubscription: Subscription;
    radiobuttonDivStyles: Object;
    questionId = TakeSurveyData.QUESTION_ID;
    form: UntypedFormGroup;
    otherChoice: ItemOption = {};
    horizontalLayoutType = SurveyLayoutType.HORIZONTAL;
    getQuestionItemInnerCssClass = getQuestionItemInnerCssClass;
    prepareStyle = prepareStyle;
    private componentDestroyed = new Subject();
    styleObj: Object;
    cellStyles = [];
    isMobile: boolean;
    isTablet: boolean;
    defaultLayout: string;
    layoutClass: string;
    enabled_choices: ItemOption[];
    enabled_regular_choices: ItemOption[];

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.checkLayout();
    }

    constructor(
        private sharedService: SharedService,
        private changeDetectorRef: ChangeDetectorRef,
        private printService: PrintService,
        private elem: ElementRef
        ) { }

    ngOnInit() {
        this.defaultLayout = this.questionItem.layout;
        this.createFormGroup();
        [this.styleObj, this.cellStyles] = this.prepareStyle(this.questionItem, this.enabled_choices);
        this.layoutClass = this.getQuestionItemInnerCssClass(this.questionItem);
        this.radiobuttonDivStyles = { width: 100 / this.enabled_choices.length + '%'};
        this.checkLayout();
        this.form.valueChanges
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(s => this.updated.emit(s));
        if (this.isMatrix || this.questionItem["answer"]) {
            this.updated.emit(this.form.value);
        }
        this.textSubscription = this.sharedService.surveyText.subscribe(text => {
            this.surveyText = { ...text };
            if (!this.changeDetectorRef['destroyed']) {
                this.changeDetectorRef.detectChanges();
            }
        });
    }

    ngAfterViewInit(): void {
        if (!this.isMatrix) {
            this.printService.waitForImageLoadingAndMarkReady(this.questionItem.id, this.elem);
        }
    }

    private createFormGroup(): void {
        let selectedChoiceId;
        // TODO: randomize only on preview
        if (this.questionItem.randomize) {
            this.questionItem.choices = _.shuffle(this.questionItem.choices);
        }

        this.enabled_choices = getEnabledChoices(this.questionItem.choices);
        this.enabled_regular_choices = getEnabledRegularChoices(this.enabled_choices);

        if (this.questionItem.value) {
            selectedChoiceId = this.questionItem.value;
        } else {
            const selectedChoice = _.find(this.enabled_choices, { is_default: true });
            selectedChoiceId = selectedChoice ? selectedChoice.id : null;
        }

        if (this.questionItem.allow_other) {
            this.otherChoice = _.find(this.enabled_choices, { is_other: true }) || {}
        }

        this.form = new UntypedFormGroup({
            id: new UntypedFormControl(this.questionItem.id),
            value: new UntypedFormControl(selectedChoiceId),
            other_value: new UntypedFormControl(this.questionItem.other_value)
        });
    }

    resetForm() {
        this.form.get('value').reset();
        this.form.get('other_value').reset();
    }

    checkLayout() {
        this.isMobile = window.innerWidth < WINDOW_WIDTH.mobileWidth;
        this.isTablet = window.innerWidth < WINDOW_WIDTH.tabletWidth;

        if (this.isMobile || (this.isTablet && this.isMatrix)) {
            this.questionItem.layout = 'Vertical';
            this.layoutClass = this.getQuestionItemInnerCssClass({
                'question_text_position': 'top',
                'item_position': 'left',
                'layout': 'Vertical'
            });
            [this.styleObj, this.cellStyles] = [{}, []];
        } else {
            this.questionItem.layout = this.defaultLayout;
            this.layoutClass = this.getQuestionItemInnerCssClass(this.questionItem);
            [this.styleObj, this.cellStyles] = this.prepareStyle(this.questionItem, this.enabled_choices);
        }
    }

    ngOnDestroy() {
        this.textSubscription.unsubscribe();
    }
}
