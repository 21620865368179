import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { SurveyQuestionType } from '../../../../../infrastructure/consts/surveys.consts';
import { SharedService } from '../../../../../infrastructure/services';
import { NetPromoterScoreItem } from '../../../../../shared/models/survey-items/question-items/netPromoterScoreItem';
import { SurveyEditorValidator } from '../../../../../infrastructure/validators/survey-editor.validators';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { AutoUnsubscribe } from '../../../../../shared/decorators/autoUnsubscribe.decorator';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'cb-net-promoter-score',
    templateUrl: './net-promoter-score.component.html',
    styleUrls: ['./net-promoter-score.component.scss']
})
@AutoUnsubscribe()
export class NetPromoterScoreComponent implements OnInit, OnDestroy {
    @Input() questionItem: NetPromoterScoreItem;

    form: UntypedFormGroup;
    netPromoteScore = SurveyQuestionType.NET_PROMOTER_SCORE;
    list: string;
    itemPosition: string;
    textPosition: string;
    private componentDestroyed = new Subject();

    constructor(
        private fb: UntypedFormBuilder,
        private sharedService: SharedService,
        private translate: TranslateService
    ) {}

    public static onNumericKeyUp(event: any) {
        return SurveyEditorValidator.validateNumericInput(event);
    }

    ngOnInit() {
        this.createFormGroup();
        this.createQuestionItemObject();
        this.assignDefaultText();
    }

    assignDefaultText() {
        if (!this.questionItem.question_text) {
            this.translate
                .get('SURVEY-EDITOR.ITEMS.NET-PROMOTER.DEFAULT-TEXT')
                .subscribe((text: string) => {
                    this.questionItem.question_text = `<p>${text}</p>`;
                });
        }
    }
    itemPositionSelected(value): void {
        this.itemPosition = value;
        this.form.controls['item_position'].setValue(value);
    }

    labelPositionSelected(value): void {
        this.textPosition = value;
        this.form.controls['question_text_position'].setValue(value);
    }

    private createFormGroup(): void {
        let isAllQuestionsRequired = false;
        if (!this.questionItem) {
            this.questionItem = <NetPromoterScoreItem>{};
        }
        if (!this.questionItem.id) {
            isAllQuestionsRequired = this.sharedService.surveyRespondentSettings.getValue() ?
            this.sharedService.surveyRespondentSettings.getValue().makeQuestionsRequired : false;
        }
        this.form = this.fb.group({
            max_length: new UntypedFormControl(this.questionItem.max_length),
            alias: new UntypedFormControl(this.questionItem.alias),
            is_required: new UntypedFormControl(this.questionItem.is_required || isAllQuestionsRequired),
            is_soft_required: new UntypedFormControl(this.questionItem.softRequiredEnabled ? !!this.questionItem.is_soft_required : false),
            default_text: new UntypedFormControl(this.questionItem.default_text),
            html_class: new UntypedFormControl(this.questionItem.html_class),
            answer_format: new UntypedFormControl(this.questionItem.answer_format),
            min_value: new UntypedFormControl(this.questionItem.min_value),
            max_value: new UntypedFormControl(this.questionItem.max_value),
            item_position: new UntypedFormControl(this.questionItem.item_position),
            question_text_position: new UntypedFormControl(
                this.questionItem.question_text_position
            ),
            layout: new UntypedFormControl(
                this.questionItem.layout
                    ? this.questionItem.layout
                    : 'Horizontal'
            )
        });

        this.itemPosition = this.questionItem.item_position;
        this.textPosition = this.questionItem.question_text_position;
    }

    private createQuestionItemObject(): void {
        this.updateQuestionItemObject();
        this.form.valueChanges
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(() => {
                this.updateQuestionItemObject();
            });
    }

    private updateQuestionItemObject(): void {
        const control = this.form.controls;
        const questionItem = new NetPromoterScoreItem();
        questionItem.item_type = SurveyQuestionType.NET_PROMOTER_SCORE;
        questionItem.max_length = control['max_length'].value;
        questionItem.is_required = control['is_required'].value;
        questionItem.is_soft_required = control['is_soft_required'].value;
        questionItem.alias = control['alias'].value;
        questionItem.default_text = control['default_text'].value;
        questionItem.html_class = control['html_class'].value;
        questionItem.answer_format = control['answer_format'].value;
        questionItem.question_text_position = control['question_text_position'].value;
        questionItem.item_position = control['item_position'].value;
        questionItem.layout = control['layout'].value;
        this.sharedService.share('questionItemSettings', questionItem);
    }

    ngOnDestroy() {}
}
