import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { HyperlinkItem } from '../../../../../shared/models/survey-items/action-items/hyperlinkItem';
import {
    UntypedFormGroup,
    UntypedFormBuilder,
    UntypedFormControl,
    Validators
} from '@angular/forms';
import { SharedService } from '../../../../../infrastructure/services';
import { HyperLinkOptionType } from '../../../../../infrastructure/consts/surveys.consts';
import { Subject } from 'rxjs';
import { AutoUnsubscribe } from '../../../../../shared/decorators/autoUnsubscribe.decorator';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'cb-hyperlink-item',
    templateUrl: './hyperlink-item.component.html',
    styleUrls: ['./hyperlink-item.component.scss']
})
@AutoUnsubscribe()
export class HyperlinkItemComponent implements OnInit, OnDestroy {
    @Input() actionItem: HyperlinkItem;

    form: UntypedFormGroup;
    redirect_url: string;
    redirect_cursor = 0;

    redirect_text: string;

    color: string;
    options: string;
    newWindow: boolean;
    restart_survey: boolean;
    auto_redirect: boolean;
    itemPosition: string;
    linkOptionType = HyperLinkOptionType;
    private componentDestroyed = new Subject();

    constructor(
        private fb: UntypedFormBuilder,
        private sharedService: SharedService
    ) {
    }

    ngOnInit() {
        this.createForm();
        this.createActionItemObject();
    }

    public setFormVontrolValue($event, controlName: string) {
        this.form['controls'][controlName].setValue($event);
    }

    itemPositionSelected(value): void {
        this.itemPosition = value;
        this.form.controls['item_position'].setValue(value);
    }

    private createForm() {
        if (!this.actionItem) {
            this.actionItem = <HyperlinkItem>{};
        }

        this.form = this.fb.group({});
        this.form.addControl(
            'auto_redirect_delay',
            new UntypedFormControl(this.actionItem.auto_redirect_delay, [
                Validators.min(0)
            ])
        );
        this.form.addControl(
            'item_position',
            new UntypedFormControl(this.actionItem.item_position)
        );
        this.form.addControl(
            'font_color',
            new UntypedFormControl(this.actionItem.font_color)
        );
        this.form.addControl(
            'font_size',
            new UntypedFormControl(this.actionItem.font_size)
        );
        this.form.addControl(
            'link_option_value',
            new UntypedFormControl(
                this.getLinkOptionValue(
                    this.actionItem.auto_redirect,
                    this.actionItem.restart_survey
                )
            )
        );
        this.form.addControl(
            'window_option_value',
            new UntypedFormControl(!!this.actionItem.open_in_new_window)
        );
        this.form.addControl(
            'url',
            new UntypedFormControl({
                value: this.actionItem.url,
                disabled:
                    this.form['controls']['link_option_value'].value ===
                        this.linkOptionType.LINK_TO_RESTART ||
                    this.form['controls']['link_option_value'].value ===
                        this.linkOptionType.RESTART_SURVEY
            })
        );
        this.form.addControl(
            'text',
            new UntypedFormControl({
                value: this.actionItem.text,
                disabled:
                    this.form['controls']['link_option_value'].value ===
                        this.linkOptionType.REDIRECT_TO_URL ||
                    this.form['controls']['link_option_value'].value ===
                        this.linkOptionType.RESTART_SURVEY
            })
        );
        this.color = this.actionItem.font_color;
        this.newWindow = this.actionItem.open_in_new_window;
        this.itemPosition = this.actionItem.item_position;
    }

    private getLinkOptionValue(
        auto_redirect: boolean,
        restart_survey: boolean
    ) {
        if (!restart_survey && !auto_redirect) {
            return this.linkOptionType.LINK_TO_URL;
        }
        if (restart_survey && !auto_redirect) {
            return this.linkOptionType.LINK_TO_RESTART;
        }
        if (!restart_survey && auto_redirect) {
            return this.linkOptionType.REDIRECT_TO_URL;
        }
        if (restart_survey && auto_redirect) {
            return this.linkOptionType.RESTART_SURVEY;
        }
    }

    public onLinkOptionChanged($event) {
        switch ($event.value) {
            case this.linkOptionType.LINK_TO_RESTART:
                this.form['controls']['url'].disable();
                this.form['controls']['text'].enable();
                break;
            case this.linkOptionType.REDIRECT_TO_URL:
                this.form['controls']['url'].enable();
                this.form['controls']['text'].disable();
                break;
            case this.linkOptionType.RESTART_SURVEY:
                this.form['controls']['url'].disable();
                this.form['controls']['text'].disable();
                break;
            default:
                this.form['controls']['url'].enable();
                this.form['controls']['text'].enable();
        }
    }

    public showRedirectInput() {
        return (
            this.form['controls']['link_option_value'].value ===
                this.linkOptionType.REDIRECT_TO_URL ||
            this.form['controls']['link_option_value'].value ===
                this.linkOptionType.RESTART_SURVEY
        );
    }

    private convertLinkOptionValueToBoolean(
        value: string,
        isRedirect?
    ): boolean {
        if (
            value === this.linkOptionType.RESTART_SURVEY ||
            (value === this.linkOptionType.REDIRECT_TO_URL && isRedirect) ||
            (value === this.linkOptionType.LINK_TO_RESTART && !isRedirect)
        ) {
            return true;
        }
        return false;
    }

    private createActionItemObject(): void {
        const control = this.form.controls;
        this.form.valueChanges
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(s => {
                const actionItem = {
                    ...this.actionItem,
                    ...(<HyperlinkItem>{
                        url: control['url'].value,
                        text: control['text'].value,
                        auto_redirect_delay: control['auto_redirect_delay'].value,
                        item_position: control['item_position'].value,
                        font_color: this.color,
                        font_size: control['font_size'].value,
                        auto_redirect: this.convertLinkOptionValueToBoolean(
                            control['link_option_value'].value,
                            true
                        ),
                        restart_survey: this.convertLinkOptionValueToBoolean(
                            control['link_option_value'].value
                        ),
                        open_in_new_window: control['window_option_value'].value
                    })
                };
                this.sharedService.share('actionItemSettings', actionItem);
        });
    }

    ngOnDestroy() {}
}
