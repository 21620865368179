import { Component, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { SharedService } from '../../../../../../infrastructure/services';
import { SurveyQuestionType, SurveysPagesActions } from '../../../../../../infrastructure/consts/surveys.consts';
import { QuestionItem } from '../../../../../../shared/models/survey-items/question-items/questionItem';
import * as _ from 'lodash';
import { TinyRichEditorComponent } from '../../../../../../shared/components/rich-editor/tiny-rich-editor.component';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AutoUnsubscribe } from '../../../../../../shared/decorators/autoUnsubscribe.decorator';

@Component({
    selector: 'cb-question-and-subtext',
    templateUrl: './question-and-subtext.component.html',
    styleUrls: ['./question-and-subtext.component.scss']
})
@AutoUnsubscribe()
export class QuestionAndSubtextComponent implements OnInit, OnDestroy {
    @Input() questionItem: QuestionItem;
    @Input() isMatrix: boolean;
    @Input() isSubquestion: boolean;
    @ViewChild('questionEditor', { static: true }) private questionEditor: TinyRichEditorComponent;
    @ViewChild('subtextEditor') private subtextEditor: TinyRichEditorComponent;

    form: UntypedFormGroup;
    questionEditorCursorPos = 0;
    subtextEditorCursorPos = 0;
    questionEditorText = '';
    subtextEditorText = '';
    private componentDestroyed = new Subject();

    constructor(
        private fb: UntypedFormBuilder,
        private sharedService: SharedService
    ) {}

    ngOnInit() {
        let question_text;
        let subtext;

        if (this.questionItem) {
            question_text = this.questionEditorText = this.questionItem.question_text || '';
            subtext = this.subtextEditorText = this.questionItem.subtext || '';
        }

        this.form = this.fb.group({
            question_text: new UntypedFormControl(question_text),
            subtext: new UntypedFormControl(subtext),
            mergeToQuestionControl: new UntypedFormControl()
        });

        const updateTextEventName = this.questionItem.item_type === SurveyQuestionType.MATRIX
            ? 'matrixItem'
            : (this.isSubquestion ? SurveysPagesActions.UPDATE_SUBQUESTION_TEXT : 'questionItem');

        this.form.valueChanges
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(s =>
                this.sharedService.share(updateTextEventName, s)
            );

        this.sharedService.share(updateTextEventName, this.form.value);
    }

    onMergeToQuestionControl(mergeCode) {
        this.questionEditor.insertMergeCode(mergeCode);
    }

    onMergeToSubtextControl(mergeCode) {
        this.subtextEditor.insertMergeCode(mergeCode);
    }

    ngOnDestroy() {}
}
