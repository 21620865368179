<form [formGroup]="form" novalidate class="cb-rename-page-form">
    <mat-form-field
        class="cb-page-name-input"
        floatLabel="never"
        (click)="showWarningIfLocked()">
        <mat-label>{{ 'SURVEY-EDITOR.PAGES.PAGE-NAME' | translate }}</mat-label>
        <input
            matInput
            outocomplete="off"
            formControlName="name"
            [maxLength]="32"
        />
    </mat-form-field>
</form>
