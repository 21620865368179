<form [formGroup]="form" autocomplete="off">
    <div class="form-section">
        <cb-question-item-title></cb-question-item-title>
        <div class="cb-change-type-section">
            <cb-item-type [itemType]="maxDiff"></cb-item-type>
        </div>
        <cb-question-and-subtext
            [questionItem]="questionItem"
        ></cb-question-and-subtext>
    </div>
    <div class="form-section">
        <cb-multiple-options-item
            [surveyId]="questionItem.surveyId"
            [pageId]="questionItem.page_id"
            [options]="questionItem.choices"
            [expanded]="true"
            [questionType]="maxDiff"
            [resetChoicesEvent] = "resetChoicesSubject.asObservable()"
        >
        </cb-multiple-options-item>
    </div>
    <cb-behavior-options [parentForm]="form" [softRequiredEnabled]="questionItem.softRequiredEnabled">
        <div class="form-fields-group">
            <mat-form-field class="cb-form-field">
                <mat-label>{{
                        'SURVEY-EDITOR.ITEMS.MAX-DIFF.BEST-LABEL'
                            | translate
                    }}</mat-label>
                <input
                    matInput
                    autocomplete="off"
                    type="text"
                    formControlName="best_label"
                />
            </mat-form-field>
            <mat-form-field class="cb-form-field">
                <mat-label>{{
                        'SURVEY-EDITOR.ITEMS.MAX-DIFF.WORST-LABEL'
                            | translate
                    }}</mat-label>
                <input
                    matInput
                    type="text"
                    autocomplete="off"
                    formControlName="worst_label"
                />
            </mat-form-field>
            <mat-form-field class="cb-form-field">
                <mat-label>{{
                        'SURVEY-EDITOR.ITEMS.MAX-DIFF.ITEM-LABEL'
                            | translate
                    }}</mat-label>
                <input
                    matInput
                    type="text"
                    autocomplete="off"
                    formControlName="item_label"
                    value="Item"
                />
            </mat-form-field>
        </div>
        <div class="form-fields-group">
            <mat-form-field class="cb-form-field">
                <mat-label>{{
                        'SURVEY-EDITOR.ITEMS.MAX-DIFF.NUMBER-OF-SETS'
                            | translate
                    }}</mat-label>
                <input
                    matInput
                    maxlength="2"
                    autocomplete="off"
                    (keypress)="onNumericKeyUp($event)"
                    formControlName="number_sets"
                />
                <mat-error>
                    {{ getControlErrors('number_sets') }}
                </mat-error>
            </mat-form-field>
            <mat-form-field class="cb-form-field">
                <mat-label>{{
                        'SURVEY-EDITOR.ITEMS.MAX-DIFF.NUMBER-OF-CHOICES'
                            | translate
                    }}</mat-label>
                <input
                    matInput
                    maxlength="2"
                    (keypress)="onNumericKeyUp($event)"
                    formControlName="number_items_per_set"
                />
                <mat-error>
                    {{ getControlErrors('number_items_per_set') }}
                </mat-error>
            </mat-form-field>
        </div>
    </cb-behavior-options>
    <div class="form-section border-top">
        <cb-question-collapsed-panel
            title="{{
                'SURVEY-EDITOR.SHARED.APPEARANCE.APPEARANCE' | translate
            }}"
        >
            <div class="form-group-position">
                <cb-label-position
                    class="position-control-group"
                    [selectedOption]="textPosition"
                    (selected)="onLabelPositionSelected($event)"
                >
                </cb-label-position>
                <cb-item-position
                    class="position-control-group"
                    [selectedOption]="itemPosition"
                    (selected)="onItemPositionSelected($event)"
                ></cb-item-position>
            </div>
            <div class="form-fields-group">
                <mat-form-field class="cb-form-field">
                    <mat-label>{{
                            'SURVEY-EDITOR.SHARED.LAYOUT' | translate
                        }}</mat-label>
                    <mat-select
                        formControlName="orientation"
                    >
                        <mat-option
                            [value]="maxDiffItemOrientationEnum.WORST_ITEM_BEST"
                        >
                            {{
                                'SURVEY-EDITOR.ITEMS.MAX-DIFF.WORST' | translate
                            }}
                            |
                            {{
                                'SURVEY-EDITOR.ITEMS.MAX-DIFF.ITEM' | translate
                            }}
                            |
                            {{
                                'SURVEY-EDITOR.ITEMS.MAX-DIFF.BEST' | translate
                            }}
                        </mat-option>
                        <mat-option
                            [value]="maxDiffItemOrientationEnum.BEST_ITEM_WORST"
                        >
                            {{
                                'SURVEY-EDITOR.ITEMS.MAX-DIFF.BEST' | translate
                            }}
                            |
                            {{
                                'SURVEY-EDITOR.ITEMS.MAX-DIFF.ITEM' | translate
                            }}
                            |
                            {{
                                'SURVEY-EDITOR.ITEMS.MAX-DIFF.WORST' | translate
                            }}
                        </mat-option>
                        <mat-option
                            [value]="maxDiffItemOrientationEnum.ITEM_WORST_BEST"
                        >
                            {{
                                'SURVEY-EDITOR.ITEMS.MAX-DIFF.ITEM' | translate
                            }}
                            |
                            {{
                                'SURVEY-EDITOR.ITEMS.MAX-DIFF.WORST' | translate
                            }}
                            |
                            {{
                                'SURVEY-EDITOR.ITEMS.MAX-DIFF.BEST' | translate
                            }}
                        </mat-option>
                        <mat-option
                            [value]="maxDiffItemOrientationEnum.ITEM_BEST_WORST"
                        >
                            {{
                                'SURVEY-EDITOR.ITEMS.MAX-DIFF.ITEM' | translate
                            }}
                            |
                            {{
                                'SURVEY-EDITOR.ITEMS.MAX-DIFF.BEST' | translate
                            }}
                            |
                            {{
                                'SURVEY-EDITOR.ITEMS.MAX-DIFF.WORST' | translate
                            }}
                        </mat-option>
                        <mat-option
                            [value]="maxDiffItemOrientationEnum.WORST_BEST_ITEM"
                        >
                            {{
                                'SURVEY-EDITOR.ITEMS.MAX-DIFF.WORST' | translate
                            }}
                            |
                            {{
                                'SURVEY-EDITOR.ITEMS.MAX-DIFF.BEST' | translate
                            }}
                            |
                            {{
                                'SURVEY-EDITOR.ITEMS.MAX-DIFF.ITEM' | translate
                            }}
                        </mat-option>
                        <mat-option
                            [value]="maxDiffItemOrientationEnum.BEST_WORST_ITEM"
                        >
                            {{
                                'SURVEY-EDITOR.ITEMS.MAX-DIFF.BEST' | translate
                            }}
                            |
                            {{
                                'SURVEY-EDITOR.ITEMS.MAX-DIFF.WORST' | translate
                            }}
                            |
                            {{
                                'SURVEY-EDITOR.ITEMS.MAX-DIFF.ITEM' | translate
                            }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </cb-question-collapsed-panel>
    </div>
    <div class="form-section">
        <cb-question-collapsed-panel
            title="{{ 'SURVEY-EDITOR.SHARED.CONDITIONS' | translate }}"
            [tooltip]="'SURVEY-EDITOR.SHARED.TOOLTIPS.CONDITIONS' | translate"
        >
            <cb-groups-expressions-element
                [source]="questionItem"
            ></cb-groups-expressions-element>
        </cb-question-collapsed-panel>
    </div>
</form>
