import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { EmailAlertItem } from '../../../../../shared/models/survey-items/action-items/emailAlertItem';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { SharedService } from '../../../../../infrastructure/services';
import { StyleTemplatesProvider } from '../../../../../infrastructure/providers/style-templates.provider';
import { TinyRichEditorComponent } from '../../../../../shared/components/rich-editor/tiny-rich-editor.component';
import { Subject } from 'rxjs';
import { AutoUnsubscribe } from '../../../../../shared/decorators/autoUnsubscribe.decorator';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'cb-email-alert-item',
    templateUrl: './email-alert-item.component.html',
    styleUrls: ['./email-alert-item.component.scss']
})
@AutoUnsubscribe()
export class EmailAlertItemComponent implements OnInit, OnDestroy {
    @Input() actionItem: EmailAlertItem;
    @ViewChild('messageBodyEditor') private messageBodyEditor: TinyRichEditorComponent;

    form: UntypedFormGroup;
    messageFormat: string;
    editorText = '';
    editorCursorPos: number;
    styleTemplateList;
    private componentDestroyed = new Subject();

    constructor(
        private fb: UntypedFormBuilder,
        private sharedService: SharedService,
        private styleProvider: StyleTemplatesProvider
    ) {}

    ngOnInit() {
        this.messageFormat = 'Html';
        this.createForm();
        this.createActionItemObject();
        this.getStyleTemplateList();
    }

    public onFormatChanged($event) {
        this.messageFormat = $event.value;
        this.form['controls']['format'].setValue(this.messageFormat);
    }

    public onSendOnceChanged($event) {
        this.form['controls']['send_once'].setValue($event.checked);
    }

    onMergeText(mergeCode, control) {
        const field = this.form.controls[control];
        const newValue = field.value ? field.value + ' ' + mergeCode : mergeCode;
        field.setValue(newValue);
    }

    getStyleTemplateList() {
        this.styleProvider.getStyleTemplateSimpleList()
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe((data: any) => {
                this.styleTemplateList = data;
            });
    }

    mergeInTinyEditor(mergeCode: string) {
        this.messageBodyEditor.insertMergeCode(mergeCode);
    }

    // onContentChanged($event) {
    //     this.editorText = $event.text;
    //     this.editorCursorPos = $event.editor.selection.savedRange.index;
    // }
    //
    // onSelectionChanged($event) {
    //     if ($event && $event.range) {
    //         this.editorCursorPos = $event.range.index;
    //     }
    // }

    private createForm() {
        if (!this.actionItem) {
            this.actionItem = <EmailAlertItem>{};
        }

        this.form = this.fb.group({});
        this.form.addControl('from', new UntypedFormControl(this.actionItem.from));
        this.form.addControl('to', new UntypedFormControl(this.actionItem.to));
        this.form.addControl('bcc', new UntypedFormControl(this.actionItem.bcc));
        this.form.addControl(
            'subject',
            new UntypedFormControl(this.actionItem.subject)
        );
        this.form.addControl('body', new UntypedFormControl(this.actionItem.body));
        this.form.addControl('format', new UntypedFormControl(this.actionItem.format));
        this.form.addControl(
            'send_once',
            new UntypedFormControl(!!this.actionItem.send_once)
        );
        this.form.addControl(
            'style_template_id',
            new UntypedFormControl(this.actionItem.style_template_id)
        );
    }

    private createActionItemObject(): void {
        const control = this.form.controls;
        this.form.valueChanges
            .pipe(
                takeUntil(this.componentDestroyed)
            )
            .subscribe(s => {
                const actionItem = <EmailAlertItem>{
                    from: control['from'].value,
                    to: control['to'].value,
                    bcc: control['bcc'].value,
                    subject: control['subject'].value,
                    body: control['body'].value,
                    format: control['format'].value,
                    send_once: control['send_once'].value,
                    style_template_id: control['style_template_id'].value
                };
            this.actionItem = { ...this.actionItem, ...actionItem };
            this.sharedService.share('actionItemSettings', this.actionItem);
        });
    }

    ngOnDestroy() {}
}
