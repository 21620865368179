import { mergeMap } from 'rxjs/operators';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { StorageProvider, AccountNameResolverProvider } from '../providers';

import { environment } from '../../environments/environment';
import { EnvironmentProvider } from '../providers/environment.provider';

Injectable();
export class UrlSanitizerInterceptor implements HttpInterceptor {
    // tslint:disable-next-line:max-line-length
    private urlRegex = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gim;
    private hostRegex = /\{host\}/gim;
    private accountRegex = /\{account\}/gim;

    constructor(
        private storageProvider: StorageProvider,
        private accountNameResolverProvider: AccountNameResolverProvider,
        private environmentProvider: EnvironmentProvider
    ) {}

    private get hostname(): string {
        return window.location.hostname;
    }

    private get rootApiUrl(): string {
        return this.environmentProvider.apiUrl;
    }

    private mergeAccountNameToUrl(req: HttpRequest<any>, account: string) {
        const url =
            this.rootApiUrl + req.url.replace(this.accountRegex, account);
        return req.clone({ url: url });
    }

    private getServerVersionRequest(req) {
        const url =
            this.rootApiUrl +
            req.url.replace(this.accountRegex, '').replace('//', '/');
        return req.clone({ url: url });
    }

    private processRequest(
        req: HttpRequest<any>,
        next: HttpHandler,
        account: string
    ) {
        if (this.accountRegex.test(req.url)) {
            req = this.mergeAccountNameToUrl(req, account);
            return next.handle(req);
        }
        return next.handle(req);
    }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (this.urlRegex.test(req.url)) {
            return next.handle(req);
        }
        if (this.hostRegex.test(req.url)) {
            const url =
                this.rootApiUrl +
                req.url.replace(this.hostRegex, this.hostname);
            req = req.clone({ url: url });
            return next.handle(req);
        }

        if (environment.name === 'server') {
            if (this.accountRegex.test(req.url)) {
                return next.handle(this.getServerVersionRequest(req));
            }
            return next.handle(req);
        }

        if (environment.isAdminSite) {
            return this.storageProvider.getAccount().pipe(
                mergeMap((account: string) => {
                    return this.processRequest(req, next, account);
                })
            );
        } else {
            return this.accountNameResolverProvider.getTakeSurveyAccount().pipe(
                mergeMap((takeSurveyAccount: string) => {
                    return this.processRequest(req, next, takeSurveyAccount);
                })
            );
        }
    }
}
