import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SurveyFolder } from '../../../../models';
import { FoldersProvider, StyleTemplatesProvider, SurveysProvider, SurveyTemplatesProvider } from '../../../../providers';
import { AllSurveysListComponent } from '../../surveys-list/all-surveys-list/all-surveys-list.component';
import { ApplicationSettingsProvider } from '../../../../settings/application-settings/application-settings.provider';
import { forkJoin, Subject } from 'rxjs';
import * as _ from 'lodash';
import { AutoUnsubscribe } from '../../../../../shared/decorators/autoUnsubscribe.decorator';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'cb-add-survey-dialog',
    templateUrl: './add-survey-dialog.component.html',
    styleUrls: ['../add-dialog.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
@AutoUnsubscribe()
export class AddSurveyDialogComponent implements OnInit, OnDestroy {
    isShowCustomUrl = false;
    public form: UntypedFormGroup;
    folders: SurveyFolder[];
    survey: any;
    isLoading: boolean;
    styleTemplateId: number;
    surveyTemplates;
    private componentDestroyed = new Subject();
    public isFromFile: boolean;
    public selectedFile: File;
    isSurveyTemplate = false;

    constructor(
        private fb: UntypedFormBuilder,
        private router: Router,
        private surveysProvider: SurveysProvider,
        private folderProvider: FoldersProvider,
        private applicationSettingsProvider: ApplicationSettingsProvider,
        private styleTemplateProvider: StyleTemplatesProvider,
        private dialogRef: MatDialogRef<AllSurveysListComponent>,
        private ref: ChangeDetectorRef,
        private surveyTemplatesProvider: SurveyTemplatesProvider,
        @Inject(MAT_DIALOG_DATA) public dialogData
    ) {
        this.isSurveyTemplate = dialogData ? dialogData.isTemplate : false;
    }

    ngOnInit() {
        this.initData();
        this.initForm();
    }

    initForm() {
        this.isFromFile = this.dialogData && this.dialogData['fromFile'];
        this.form = this.fb.group({
            name: ['', Validators.required],
            custom_url: [null]
        });
        if (this.isSurveyTemplate) {
            this.form.addControl('description', new UntypedFormControl(''));
        } else {
            this.form.addControl('survey_folder_id', new UntypedFormControl(-1));
            this.form.addControl('survey_template_id', new UntypedFormControl(-1));
        }
    }

    setCustomUrl(event: KeyboardEvent) {
        if (this.isShowCustomUrl && !this.isSurveyTemplate) {
            let customUrl = (<HTMLInputElement>event.target).value;

            let result = '';
            for (let j = 0; j < customUrl.length; j++) {
                const char = customUrl.charAt(j);
                const hh = char.charCodeAt(0);
                if (
                    hh === 45 ||
                    (hh > 47 && hh < 58) ||
                    (hh > 64 && hh < 91) ||
                    (hh > 96 && hh < 123)
                ) {
                    result += char;
                }
                if (hh === 32) {
                    result += '-';
                }
            }
            customUrl = encodeURI(result);
            this.form.controls['custom_url'].setValue(customUrl);
        }
    }

    onSubmit(): void {
        const data = this.form.value;
        if (data.survey_folder_id === -1) {
            data.survey_folder_id = null;
        }
        if (data.survey_template_id === -1) {
            data.survey_template_id = null;
        }
        data.style_template_id = !data.survey_template_id ? this.styleTemplateId : null;
        let request = this.surveysProvider.addSurvey(data, this.isSurveyTemplate);
        if (this.isFromFile) {
            data.file = this.selectedFile;
            delete data.survey_template_id;

            request = this.surveysProvider.addSurveyFromFile(data);
        }
        request.pipe(
                takeUntil(this.componentDestroyed)
            )
            .subscribe(
                s => {
                    this.dialogRef.close(s);
                },
                error => this.errorHandler(error)
            );
    }
    cancel() {
        this.dialogRef.close(null);
    }
    fileChanged(loadedFiles: File[]) {
        if (loadedFiles.length) {
            this.selectedFile = loadedFiles[0];
        }
    }

    initData(): void {
        this.isLoading = true;
        this.ref.detectChanges();
        if (this.isSurveyTemplate) {
            this.initDataForTemplates();
        } else {
            this.initDataForSurvey();
        }
    }

    initDataForSurvey() {
        forkJoin([
            this.styleTemplateProvider.getStyleTemplateSimpleList(),
            this.folderProvider.loadFolders(),
            this.applicationSettingsProvider.getSurveyApplicationSettings(),
            this.surveyTemplatesProvider.loadAllTemplates()
        ])
            .pipe(
                takeUntil(this.componentDestroyed)
            )
            .subscribe(([styleTemplateList, folders, appSurveySettings, surveyTemplates]) => {
                this.folders = folders;
                this.isShowCustomUrl = appSurveySettings.survey_preferences.enable_custom_survey_urls;
                const defaultStyleTemplateId = appSurveySettings.survey_preferences.default_survey_style_template;
                this.styleTemplateId = _.chain(styleTemplateList)
                    .find({ template_id: defaultStyleTemplateId })
                    .get('template_id', null)
                    .value() as number;
                this.surveyTemplates = surveyTemplates;
                this.isLoading = false;
                this.ref.detectChanges();
        }, error => this.errorHandler(error));
    }

    initDataForTemplates() {
        forkJoin([
            this.styleTemplateProvider.getStyleTemplateSimpleList(),
            this.applicationSettingsProvider.getSurveyApplicationSettings(),
        ])
            .pipe(
                takeUntil(this.componentDestroyed)
            )
            .subscribe(([styleTemplateList, appSurveySettings]) => {
                this.isShowCustomUrl = appSurveySettings.survey_preferences.enable_custom_survey_urls;
                const defaultStyleTemplateId = appSurveySettings.survey_preferences.default_survey_style_template;
                this.styleTemplateId = _.chain(styleTemplateList)
                    .find({ template_id: defaultStyleTemplateId })
                    .get('template_id', null)
                    .value() as number;
                this.isLoading = false;
                this.ref.detectChanges();
        }, error => this.errorHandler(error));
    }

    disableSubmitButton() {
        return !this.form?.valid || this.form?.value?.name?.trim() === '' || (this.isFromFile && !this.selectedFile);
    }

    private errorHandler(error) {
        this.isLoading = false;
    }

    ngOnDestroy() {}
}
