import { Component, Input, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { SurveyQuestionType } from '../../../../../infrastructure/consts/surveys.consts';
import { SharedService } from '../../../../../infrastructure/services';
import { DropdownListItem } from '../../../../../shared/models/survey-items/question-items/dropdownListItem';
import { TranslateService } from '@ngx-translate/core';
import { isHTML } from '../../../../../infrastructure/helpers/is-html.helper';
import { Subject } from 'rxjs';
import { AutoUnsubscribe } from '../../../../../shared/decorators/autoUnsubscribe.decorator';
import { takeUntil } from 'rxjs/operators';
import { MultipleChoicesItemComponent } from '../multiple-choices-item.component';

@Component({
    selector: 'cb-dropdownlist-item',
    templateUrl: './dropdownlist-item.component.html',
    styleUrls: ['./dropdownlist-item.component.scss']
})
@AutoUnsubscribe()
export class DropDownListItemComponent extends MultipleChoicesItemComponent implements OnInit, OnDestroy {
    @Input() questionItem: DropdownListItem;
    @Input() isMatrix: boolean;

    form: UntypedFormGroup;
    dropdownlist = SurveyQuestionType.DROPDOWNLIST;
    itemPosition: string;
    textPosition: string;
    private componentDestroyed = new Subject();
    otherValue = '';
    editOther: boolean;

    constructor(
        private fb: UntypedFormBuilder,
        private sharedService: SharedService,
        private translate: TranslateService,
        private cdr: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        this.createFormGroup();
        this.createQuestionItemObject();
    }

    itemPositionSelected(value): void {
        this.itemPosition = value;
        this.form.controls['item_position'].setValue(value);
    }

    labelPositionSelected(value): void {
        this.textPosition = value;
        this.form.controls['question_text_position'].setValue(value);
    }

    private createFormGroup(): void {
        let isAllQuestionsRequired = false;
        let other;
        this.translate
            .get('SURVEY-EDITOR.SHARED.OTHER')
            .subscribe((text: string) => {
                other = text;
            });
        if (!this.questionItem) {
            this.questionItem = <DropdownListItem>{};
        }
        if (!this.questionItem.id) {
            isAllQuestionsRequired = this.sharedService.surveyRespondentSettings.getValue() ?
            this.sharedService.surveyRespondentSettings.getValue().makeQuestionsRequired : false;
        }
        this.form = this.fb.group({
            // behavior
            alias: new UntypedFormControl(this.questionItem.alias),
            html_class: new UntypedFormControl(this.questionItem.html_class),
            is_required: new UntypedFormControl(this.questionItem.is_required || isAllQuestionsRequired),
            is_soft_required: new UntypedFormControl(this.questionItem.softRequiredEnabled ? !!this.questionItem.is_soft_required : false),
            randomize: new UntypedFormControl(!!this.questionItem.randomize),
            allow_other: new UntypedFormControl(!!this.questionItem.allow_other),
            other_value: new UntypedFormControl(this.questionItem.other_value),

            // appearance
            question_text_position: new UntypedFormControl(
                this.questionItem.question_text_position
            ),
            item_position: new UntypedFormControl(this.questionItem.item_position),
            show_number_labels: new UntypedFormControl(
                !!this.questionItem.show_number_labels
            )
            /*  display_as_combobox: new FormControl(
                this.questionItem.display_as_combobox
            )*/
        });
        this.otherValue = this.questionItem.other_value;
        this.form['controls'][
            'other_value'
        ].valueChanges.subscribe(
            text => {
                this.otherValue = text;
                this.cdr.detectChanges();
            }
        );
        this.itemPosition = this.questionItem.item_position;
        this.textPosition = this.questionItem.question_text_position;
    }

    private createQuestionItemObject(): void {
        this.updateQuestionItemObject();
        this.form.valueChanges
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(() => {
                this.updateQuestionItemObject();
            });
    }

    private updateQuestionItemObject(): void {
        const control = this.form.controls;
        const questionItem = new DropdownListItem();

        questionItem.item_type = SurveyQuestionType.DROPDOWNLIST;
        questionItem.alias = control['alias'].value;
        questionItem.html_class = control['html_class'].value;
        questionItem.randomize = control['randomize'].value;
        questionItem.allow_other = control['allow_other'].value;
        questionItem.other_value = control['other_value'].value;
        questionItem.is_required = control['is_required'].value;
        questionItem.is_soft_required = control['is_soft_required'].value;
        questionItem.question_text_position =
        control['question_text_position'].value;
        questionItem.item_position = control['item_position'].value;
        questionItem.show_number_labels = control['show_number_labels'].value;
        /*  questionItem.display_as_combobox = control['display_as_combobox'].value;*/
        this.sharedService.share('questionItemSettings', questionItem);
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    editItem(ref) {
        if (isHTML(this.otherValue)) {
            ref.onOpenHtmlEditorClick();
        } else {
            this.editOther = true;
        }
    }
}
