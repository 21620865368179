<cb-spinner [show]="isLoading"></cb-spinner>
<div class="cb-question-item-wrapper-outer {{ this.questionItem.html_class }}">
    <div [ngClass]="getQuestionItemInnerCssClass(questionItem)">
        <cb-questions-header
            [questionItem]="questionItem"
        ></cb-questions-header>
        <form
            [formGroup]="form"
            class="cb-question-item-content"
            *ngIf="!isPrint"
        >
            <ng-container formArrayName="levels">
                <div *ngFor="let col of form.get('levels').controls; index as groupIndex" [formGroupName]="groupIndex">
                    <div class="drop-down-item">
                        <ng-container *ngIf="showColumn(groupIndex)">
                            <mat-form-field>
                                <mat-label>{{surveyText.SelectAnOption}}</mat-label>
                                <mat-select
                                    [aria-labelledby]="questionId + questionItem.id"
                                    (selectionChange)="onSelectionChange($event, questionItem.levels[groupIndex])"
                                >
                                    <mat-option
                                        *ngFor="let item of getSelectedLevelCollection(groupIndex); trackBy: trackByItem;"
                                        [value]="item.id"

                                    >
                                        <span [innerHtml]="item.description | keepHtml"></span>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </ng-container>
                    </div>
                </div>
            </ng-container>
        </form>

        <!-- <ng-container *ngIf="isPrint">
            HERE SHOULD BE TEMPLATE FOR PRINT VERSION
        </ng-container> -->
    </div>
</div>
<cb-server-validation-errors
    [item]="questionItem"
></cb-server-validation-errors>
