<div class="infinite-container">
    <cb-spinner [show]="isLoading"></cb-spinner>
    <form *ngIf="!isLoading" [formGroup]="form" novalidate>
        <div class="infinite-title">{{ title }}</div>
        <mat-form-field class="search-input">
            <mat-label>{{ 'SHARED.SEARCH' | translate }}</mat-label>
            <input
                matInput
                formControlName="searchString"
                (focus)="searchFocus()"
                (blur)="searchBlur()"
                #s
            />
            <mat-hint
                >{{ 'ADMIN-COMPONENTS.INFINITE-LIST.START-ENTER' | translate }}
                {{ nameOfSearchSubject }}</mat-hint
            >
        </mat-form-field>

        <mat-list>
            <mat-list-item *ngFor="let item of items; let i = index">
                <div class="infinite-list-item" (click)="itemClicked(i)">
                    {{ item.name }}
                </div>

                <button
                    mat-icon-button
                    *ngIf="allowRemove"
                    (click)="onRemoveClick(i)"
                >
                    <i class="fas fa-times"></i>
                </button>
            </mat-list-item>
        </mat-list>
        <button
            *ngIf="allowMore"
            mat-raised-button
            class="infinite-button"
            (click)="getMore()"
        >
            {{ 'ADMIN-COMPONENTS.INFINITE-LIST.GET-MORE' | translate }}
        </button>
    </form>
</div>
