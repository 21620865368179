import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { RankOrderItem } from '../../../models/survey-items/question-items/rankOrder';
import { Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef, OnDestroy, ElementRef, AfterViewInit } from '@angular/core';
import { getEnabledChoices, getQuestionItemInnerCssClass } from '../../../../infrastructure/helpers/surveys.helper';
import { SurveyRankOrderType } from '../../../../infrastructure/consts/surveys.consts';
import * as _ from 'lodash';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { SurveyDefaultText } from '../../../../infrastructure/consts/surveyDefaultText';
import { SharedService } from '../../../../infrastructure/services/shared.service';
import { Subscription, Subject } from 'rxjs';
import { AutoUnsubscribe } from '../../../decorators/autoUnsubscribe.decorator';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { TakeSurveyData } from '../../../../infrastructure/consts/take-survey.consts';
import { PrintService } from '../../../../infrastructure/services';
import { RankOrderItemOption } from '../../../models/survey-items/question-items/itemOption';

@Component({
    selector: 'cb-rankorder-item-preview',
    templateUrl: './rankorder-item-preview.component.html',
    styleUrls: ['./rankorder-item-preview.component.scss']
})
@AutoUnsubscribe()
export class RankOrderPreviewItemComponent implements AfterViewInit, OnInit, OnDestroy {
    private rankOrderType = SurveyRankOrderType;
    @Input() questionItem: RankOrderItem;
    @Input() isPrint: boolean;
    @Output() updated = new EventEmitter<any>();
    selectedOptions: any[] = [];
    formGroup: UntypedFormGroup;
    ranks: any;
    getQuestionItemInnerCssClass = getQuestionItemInnerCssClass;
    questionId = TakeSurveyData.QUESTION_ID;
    surveyText = SurveyDefaultText;
    textSubscription: Subscription;
    enabled_choices: RankOrderItemOption[];

    selectsTopN = [];
    limit: number;
    private componentDestroyed = new Subject();

    constructor(private fb: UntypedFormBuilder,
        private sharedService: SharedService,
        private changeDetectorRef: ChangeDetectorRef,
        private printService: PrintService,
        private elem: ElementRef
        ) { }

    ngOnInit() {
        this.enabled_choices = getEnabledChoices(this.questionItem.choices);
        this.limit = this.questionItem.max_to_rank
            ? this.questionItem.max_to_rank
            : this.enabled_choices.length;

        if (this.questionItem.rank_order_type === this.rankOrderType.TOP_N) {
            this.initDropdownRankOrder();
        } else if (this.questionItem.rank_order_type === this.rankOrderType.NUMERIC) {
            this.initNumericRankOrder();
        } else {
            this.initDragNDropChoices();
        }

        this.textSubscription = this.sharedService.surveyText.subscribe(text => {
            this.surveyText = { ...text };
            if (!this.changeDetectorRef['destroyed']) {
                this.changeDetectorRef.detectChanges();
            }
        });
    }

    ngAfterViewInit(): void {
        this.printService.waitForImageLoadingAndMarkReady(this.questionItem.id, this.elem);
    }

    initNumericRankOrder() {
        this.formGroup = new UntypedFormGroup({
            id: new UntypedFormControl(this.questionItem.id),
            choices: this.fb.array([])
        });
        this.formGroup.valueChanges
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(s => {
                this.notifyAboutChanges(s);
            });
        this.enabled_choices.forEach(choice => {
            const choiceAnswer = this.questionItem.answer && this.questionItem.answer.choices
                ?  _.find(this.questionItem.answer.choices, { choice_id: choice.id}) as any
                : null;
            const control = new UntypedFormGroup(
                {
                    id: new UntypedFormControl(choice.id),
                    points: new UntypedFormControl(choice.points),
                    enabled: new UntypedFormControl(choice.enabled),
                    text: new UntypedFormControl(choice.text),
                    position: new UntypedFormControl(choice.position),
                    answer: new UntypedFormControl(choiceAnswer ? choiceAnswer.position: undefined),
                    itemsLimit: new UntypedFormControl(this.limit)
                },
                {
                    validators:[this.checkOnMaxOption, this.checkOnDuplicate],
                    updateOn: 'blur'
                }
            );
            if (choice.image) {
                control.addControl('image_url', new UntypedFormControl(choice.image.file_url));
                control.addControl('alias', new UntypedFormControl(choice.alias));
            }
            (<UntypedFormArray>this.formGroup.get('choices')).push(control);
        });

        this.ranks = this.formGroup.get('choices')['controls'];
    }

    initDropdownRankOrder() {
        this.formGroup = new UntypedFormGroup({
            answers: this.fb.array([])
        });

        this.formGroup.valueChanges
            .pipe(
                takeUntil(this.componentDestroyed),
                debounceTime(0)
            ).subscribe(a => {
                // workaround used! consider refactor of this part code to rely OR on template-driven forms or on reactive-driven forms.
                // ValueChanges is fired before model change does, it can cause some sort of data update delay in some components.
                // debounceTime(0) should fix it, but it still relies on execution order, which can not be guaranteed.
                this.changeChoice(a.answers);
                this.notifyAboutChanges();
            });

        this.selectsTopN = new Array(this.limit).fill(null);
        this.enabled_choices.map(ch => {
            const choiceAnswer = this.questionItem.answer && this.questionItem.answer.choices
                ? _.find(this.questionItem.answer.choices, { choice_id: ch.id}) as any
                : null;
            if (choiceAnswer && choiceAnswer.position > 0 && choiceAnswer.position <= this.limit) {
                this.selectsTopN[choiceAnswer.position - 1] = ch;
            }
        });
        this.selectsTopN.forEach((answer, i) => {
            const control = new UntypedFormGroup(
                {
                    id: new UntypedFormControl(i + 1),
                    answer: new UntypedFormControl(answer ? answer : null)
                },
                this.checkOnDuplicate
            );
            (<UntypedFormArray>this.formGroup.get('answers')).push(control);
        });
        this.ranks = this.formGroup.get('answers')['controls'];
    }

    initDragNDropChoices() {
        if (this.questionItem.choices_to_rank)
            return;

        const itemAnswer = this.questionItem.answer;
        const isSelectable = this.questionItem.rank_order_type === this.rankOrderType.SELECTABLE_DRAGN_DROP;

        this.questionItem.choices_to_rank = [];
        this.questionItem.selected_choices = [];

        this.enabled_choices.forEach(choice => {
            const selectedChoice = itemAnswer && itemAnswer.choices
                ? itemAnswer.choices.find(x => x.choice_id === choice.id)
                : null;

            if (choice.original_position == null) {
                choice.original_position = choice.position; // remember the original position for reset
            }

            if (selectedChoice) {
                choice.position = selectedChoice.position;
            }

            if (isSelectable && selectedChoice){
                this.questionItem.selected_choices.push(choice);
            } else {
                this.questionItem.choices_to_rank.push(choice);
            }
        });

        this.questionItem.choices_to_rank = _.sortBy(this.questionItem.choices_to_rank, 'position');
        this.refreshChoicesPosition(this.questionItem.choices_to_rank);

        if (isSelectable) {
            this.questionItem.selected_choices = _.sortBy(this.questionItem.selected_choices, 'position');
            this.refreshChoicesPosition(this.questionItem.selected_choices);
        }
    }

    refreshChoicesPosition(choices) {
        let i = 1;
        choices.forEach(c => {
            c.position = i;
            i++;
        });
    }

    notifyAboutChanges(eventData?) {
        this.updated.emit(eventData ?? {id: this.questionItem.id});
    }

    resetForm() {
        if (this.questionItem.rank_order_type === this.rankOrderType.TOP_N ||
            this.questionItem.rank_order_type === this.rankOrderType.NUMERIC) {
            this.ranks.forEach(rank => {
                if (rank.get('answer')) {
                    rank.get('answer').reset();
                }
            });
            return;
        }

        this.questionItem.choices_to_rank = _.chain(this.enabled_choices)
            .map(choice => {
                if (choice.original_position != null) {
                    choice.position = choice.original_position;
                }
                return choice;
            })
            .sortBy('position')
            .value();
        this.questionItem.selected_choices = [];
        this.notifyAboutChanges();
    }

    checkOnMaxOption(control) {
        if (
            control.dirty &&
            control.controls.answer.value !== null &&
            (control.controls.answer.value >
                control.controls.itemsLimit.value ||
                control.controls.answer.value < 1)
        ) {
            control.controls['answer'].setValue(null);
        }
        return null;
    }

    checkOnDuplicate(control) {
        if (control.dirty && control.controls.answer.value !== null) {
            control._parent.controls.forEach(item => {
                if (
                    item.controls.answer.value ===
                        control.controls.answer.value &&
                    item.controls.id.value !== control.controls.id.value
                ) {
                    item.controls['answer'].setValue(null);
                }
            });
        }
        return null;
    }

    changeChoice(array) {
        array.forEach(item => {
            const oldAnswer = _.find(this.enabled_choices, function(o) {
                return o.answer === item.id;
            });
            if (oldAnswer) {
                oldAnswer.answer = null;
            }
            if (item.answer) {
                const choice = _.find(this.enabled_choices, function(o) {
                    return o.id === item.answer.id;
                });
                if (choice) {
                    choice.answer = item.id;
                }
            }
        });
    }

    getRankImageValue(r) {
        if (_.get(r, 'controls.image_url')) {
            return _.get(r, 'controls.image_url.value');
        }
    }

    getRankTextValue(r) {
        return _.get(r, 'controls.text.value');
    }

    onSimpleDnDDrop(event: CdkDragDrop<any[]>) {
        moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        this.refreshChoicesPosition(this.questionItem.choices_to_rank);
        this.notifyAboutChanges();
    }

    onSelectableDnDDrop(event: CdkDragDrop<any[]>) {
        transferArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
        if (this.questionItem.selected_choices.length > this.limit) {
            this.questionItem.choices_to_rank.push(
                event.currentIndex
                    ? this.questionItem.selected_choices.shift()
                    : this.questionItem.selected_choices.pop()
            );
        }
        this.refreshChoicesPosition(this.questionItem.choices_to_rank);
        this.refreshChoicesPosition(this.questionItem.selected_choices);
        this.notifyAboutChanges();
    }

    ngOnDestroy() {
        this.textSubscription.unsubscribe();
    }
}
