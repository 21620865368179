import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SharedService } from '../../../../../../infrastructure/services';
import { SurveyQuestionType, SurveysPagesActions } from '../../../../../../infrastructure/consts/surveys.consts';
import { UntypedFormBuilder } from '@angular/forms';
import { QuestionItemDialogComponent } from '../../question-item-dialog/question-item-dialog.component';
import { SurveyQuestionOptions } from '../../../../../models/page-items.model';
import { QuestionItem } from '../../../../../../shared/models/survey-items/question-items/questionItem';
import { SurveyPage } from '../../../../../../infrastructure/models/survey-page.model';
import { DataTransformer } from '../../../../../providers/transforms/dataTransformer';
import { Subject } from 'rxjs';
import { AutoUnsubscribe } from '../../../../../../shared/decorators/autoUnsubscribe.decorator';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
    selector: 'cb-matrix-columns-item',
    templateUrl: './matrix-columns.component.html',
    styleUrls: ['./matrix-columns.component.scss']
})
@AutoUnsubscribe()
export class MatrixColumnsComponent implements OnInit, OnDestroy {
    @Input() columns: any[];
    @Input() surveyId: number;
    @Input() pageId: number;
    @Input() softRequiredEnabled: boolean;

    public questionType = SurveyQuestionType;
    public matrixColTypes = [
        [SurveyQuestionType.SUM_TOTAL,
            SurveyQuestionType.SLIDER,
            SurveyQuestionType.RADIOBUTTONS,
            SurveyQuestionType.DROPDOWNLIST],
        [SurveyQuestionType.CUSTOM_SOURCE_DROPDOWNLIST,
            SurveyQuestionType.CHECKBOXES,
            SurveyQuestionType.RATINGSCALE,
            SurveyQuestionType.SINGLE_LINE_TEXT]
    ];
    public defaultQuestionType = 'RowTexts';
    public dragAndDrop: any;
    public items: any;
    public matrixItemOptions: SurveyQuestionOptions;
    public matrixItemComponent: any;
    public matrixItemProcess = false;
    public newMatrixItem = { id: 0 };
    public matrixItemPage: SurveyPage;
    public item: QuestionItem;
    public displayColumns: any[];
    public editSurveyId: number;
    public editPageId: number;
    private componentDestroyed = new Subject();
    private isSurveyTemplate: boolean;

    constructor(
        private fb: UntypedFormBuilder,
        private sharedService: SharedService,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.isSurveyTemplate = this.route.snapshot.parent.data.isTemplate;
        if (!this.columns) {
            this.initColumns();
        } else {
            this.displayColumns = [...this.columns];
            this.sortColumnsByPosition();
            this.shareColumns();
        }
        this.editSurveyId = this.surveyId;
        this.editPageId = this.pageId;
        this.matrixItemPage = <SurveyPage>{ id: this.pageId };
        this.dragAndDropInit();
        this.subscribeToSharedService();
    }

    private initColumns(): void {
        this.displayColumns = [];
        const column = {
            position: 1,
            column_type: this.defaultQuestionType,
        };
        this.displayColumns.push(column);
        this.shareColumns();
    }

    private dragAndDropInit(): void {
        this.dragAndDrop = {
            onUpdate: (event: any) => {
                this.updatePositions();
            }
        };
    }

    private sortColumnsByPosition(): void {
        this.displayColumns.sort(function(a, b) {
            return a.position - b.position;
        });
    }

    private openQuestionItemDialog(itemType: string, questionItem: any) {
        if (
            questionItem &&
            questionItem.prototype_item &&
            !questionItem.prototype_item.position
        ) {
            questionItem.prototype_item.position = questionItem.position;
        }
        const options = {
            itemType,
            matrix: true,
            surveyId: this.editSurveyId.toString(),
            pageId: this.editPageId,
            require_unique_answers:
                questionItem !== null
                    ? questionItem.require_unique_answers
                    : false,
            itemData:
                questionItem !== null ? questionItem.prototype_item : null,
            numberOfQuestions: this.displayColumns.length,
            width: questionItem !== null ? questionItem.width : 0,
            isSurveyTemplate: this.isSurveyTemplate,
            softRequiredEnabled: this.softRequiredEnabled
        };
        this.item =
            options.itemData !== null ? options.itemData : this.newMatrixItem;
        this.matrixItemComponent = QuestionItemDialogComponent;
        this.matrixItemOptions = <SurveyQuestionOptions>options;
        this.matrixItemProcess = true;
    }

    private addColumn(data: any, column_type: string): void {
        const column = {
            position: data.position,
            column_type: column_type,
            prototype_item: data.prototype_item ? data.prototype_item : data,
            require_unique_answers: data.require_unique_answers,
            width: data.width
        };

        this.displayColumns.push(column);
        this.transformData(data);
        this.shareColumns();
    }

    private updateColumn(data: any): void {
        if (this.displayColumns && data) {
            const position = data.position;
            const i = this.displayColumns.findIndex(
                c => c.position === position
            );
            if (this.displayColumns[i]) {
                this.displayColumns[i].prototype_item = data;
                this.displayColumns[i].require_unique_answers =
                    data.require_unique_answers;
                this.displayColumns[i].width = data.width;
                this.shareColumns();
            }
        }
        this.transformData(data);
    }

    onDrop(event: CdkDragDrop<any[]>) {
        moveItemInArray(this.displayColumns, event.previousIndex, event.currentIndex);
        this.updatePositions();
    }

    updatePositions() {
        let i = 1;
        this.displayColumns.forEach(c => {
            c['position'] = i;
            i++;
        });
        this.shareColumns();
    }

    private shareColumns(): void {
        this.sharedService.share('matrixColumns', this.displayColumns);
    }

    private subscribeToSharedService() {
        this.sharedService
            .getData(SurveysPagesActions.CANCEL_MATRIX_ITEM)
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(() => {
                this.hideDialogItem();
            });
        this.sharedService
            .getData(SurveysPagesActions.ADD_MATRIX_ITEM)
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe((message: any) => {
                this.addColumn(message.data, 'Question');
                this.hideDialogItem();
            });
        this.sharedService
            .getData(SurveysPagesActions.UPDATE_MATRIX_ITEM)
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe((message: any) => {
                this.updateColumn(message.data);
                this.hideDialogItem();
            });
    }

    private hideDialogItem() {
        this.matrixItemComponent = null;
        this.matrixItemOptions = null;
        this.matrixItemProcess = false;
    }

    public onAddColumn(type): void {
        this.openQuestionItemDialog(type, null);
    }

    public onRemoveColumnClick(index: number): void {
        this.displayColumns = this.displayColumns.filter(
            c => c.position !== index + 1
        );
        this.updatePositions();
        this.shareColumns();
    }

    public onEditColumnClick(index: number): void {
        const column = this.displayColumns.find(c => c.position === index + 1);
        if (column) {
            this.openQuestionItemDialog(
                column.prototype_item.item_type,
                column
            );
        }
    }

    public transformData(model) {
        if (
            model.item_type === SurveyQuestionType.CHECKBOXES ||
            model.item_type === SurveyQuestionType.RADIOBUTTONS
        ) {
            DataTransformer.transformToSend(model);
        }
    }

    ngOnDestroy() {}
}
