<mat-expansion-panel
    class="cb-expansion-panel cb-form-expansion-panel"
    [expanded]="expanded"
>
    <mat-expansion-panel-header
        [collapsedHeight]="'48px'"
        [expandedHeight]="'48px'"
    >
        <mat-panel-title class="d-flex align-items-center">
            <span class="icon-collapse-wrap">
                <cb-svg-icon
                    class="icon icon-less"
                    [name]="'collapse-less'"
                ></cb-svg-icon>
                <cb-svg-icon
                    class="icon icon-more"
                    [name]="'collapse-more'"
                ></cb-svg-icon>
            </span>
            <h3 class="form-section-title">
                {{ 'SURVEY-EDITOR.SHARED.CHOICES' | translate }}
            </h3>
        </mat-panel-title>
    </mat-expansion-panel-header>

    <form [formGroup]="quickEnterForm" *ngIf="useQuickEnter">
        <p>
            {{ 'SURVEY-EDITOR.SHARED.CHOICE.QUICK-TITTLE' | translate }}
            <strong>{{
                'SURVEY-EDITOR.SHARED.CHOICE.QUICK-TYPES' | translate
            }}</strong>
        </p>
        <textarea
            formControlName="quickEnter"
            class="cb-quick-enter"
        ></textarea>
    </form>

    <form [formGroup]="form">
        <ng-container formArrayName="options" *ngIf="!useQuickEnter">
            <div class="choices-item multiple-options-item choices-item-header">
                <div class="choices-checkbox-group">
                    <div class="id-cell">#</div>
                    <div class="points-cell cell" *ngIf="questionType === surveyQuestions.RATINGSCALE">
                        {{ 'SURVEY-EDITOR.SHARED.CHOICE.POINTS-SCALE' | translate }}
                    </div>
                    <div
                        *ngIf="
                            questionType !== surveyQuestions.MAXDIFF &&
                            questionType !== surveyQuestions.RANKORDER &&
                            questionType !== surveyQuestions.RATINGSCALE
                        "
                        class="checkbox-cell"
                    >
                        {{ 'SURVEY-EDITOR.SHARED.CHOICE.DEFAULT' | translate }}
                    </div>
                    <div class="text-cell cell">
                        {{ (questionType !== surveyQuestions.RATINGSCALE ?
                                'SURVEY-EDITOR.SHARED.CHOICE.TEXT' :
                                'SURVEY-EDITOR.SHARED.CHOICE.TEXT-LABEL') | translate }}
                    </div>
                    <div class="html-cell cell" *ngIf="!hideHtml">&nbsp;</div>
                    <div class="merge-cell cell">&nbsp;</div>
                    <div class="alias-cell cell">
                        {{ 'SURVEY-EDITOR.SHARED.CHOICE.ALIAS' | translate }}
                        <cb-help-icon [tooltip]="'SURVEY-EDITOR.SHARED.TOOLTIPS.ALIAS-OPTIONS' | translate"></cb-help-icon>
                    </div>
                    <div class="points-cell cell" *ngIf="questionType !== surveyQuestions.RATINGSCALE">
                        {{ 'SURVEY-EDITOR.SHARED.CHOICE.POINTS' | translate }}
                        <cb-help-icon [tooltip]="'SURVEY-EDITOR.SHARED.TOOLTIPS.POINTS' | translate"></cb-help-icon>
                    </div>
                    <div
                        *ngIf="!isRatingScale"
                        class="actions-cell cell">
                        &nbsp;
                    </div>
                    <div class="actions-cell cell">&nbsp;</div>
                </div>
            </div>
            <div
                cdkDropList
                [cdkDropListData]="items"
                (cdkDropListDropped)="reorderItems($event)"
            >
                <div
                    class="choices-item multiple-options-item draggable-item shadowed-section"
                    *ngFor="let item of items; let i = index"
                    cdkDrag
                    [formGroupName]="i"
                    [cdkDragDisabled]="isIEOrEdge && item.sortable"
                    #itemChoiceElements
                >
                    <span cdkDragHandle class="icon-move">
                        <cb-svg-icon
                            class="icon"
                            [name]="'move-page'"
                        ></cb-svg-icon>
                    </span>
                    <div
                        class="choices-checkbox-group"
                        (keydown)="defaultEnterBehavior($event)"
                    >
                        <div class="id-cell">{{ i + 1 }}</div>
                        <div
                            *ngIf="
                                questionType != surveyQuestions.MAXDIFF &&
                                questionType != surveyQuestions.RANKORDER &&
                                questionType !== surveyQuestions.RATINGSCALE
                            "
                            class="checkbox-cell cell"
                        >
                            <mat-checkbox
                                class="element"
                                *ngIf="
                                    questionType === surveyQuestions.CHECKBOXES
                                "
                                formControlName="is_default"
                            ></mat-checkbox>
                            <mat-radio-button
                                class="element"
                                *ngIf="
                                    questionType ===
                                        surveyQuestions.RANKORDER ||
                                    questionType ===
                                        surveyQuestions.RADIOBUTTONS ||
                                    questionType ===
                                        surveyQuestions.DROPDOWNLIST ||
                                    questionType === surveyQuestions.RATING ||
                                    questionType === surveyQuestions.SLIDER
                                "
                                (click)="onDefaultChanged(i, $event)"
                                name="is_default"
                                id="is_default{{ i }}"
                                [value]="i"
                                [checked]="item['controls']['is_default'].value"
                            ></mat-radio-button>
                        </div>
                        <mat-form-field
                            class="points-cell cell"
                            floatLabel="never"
                            *ngIf="questionType === surveyQuestions.RATINGSCALE"
                        >
                            <mat-label>{{
                                    'SURVEY-EDITOR.SHARED.CHOICE.POINTS'
                                        | translate
                                }}</mat-label>
                            <input
                                matInput
                                type="number"
                                (focus)="onFocusDraggable(item)"
                                (blur)="onBlurDraggable(item)"
                                formControlName="points"
                            />
                        </mat-form-field>
                        <span
                            class="text-preview"
                            [innerHTML]="item.value.text"
                            (click)="editItem(i, item, ref)"
                            *ngIf="item.value.text && editedIndex !== i">
                        </span>
                        <mat-form-field
                            *ngIf="!item.value.text || editedIndex === i"
                            class="text-cell cell"
                            floatLabel="never"
                        >
                            <mat-label>{{
                                    'SURVEY-EDITOR.SHARED.CHOICE.CHOICE'
                                        | translate
                                }} {{ i + 1 }}
                                {{  questionType === surveyQuestions.RATINGSCALE ?
                                    ('SURVEY-EDITOR.SHARED.CHOICE.OPTIONAL' | translate) : ''
                                }}</mat-label>
                            <input
                                matInput
                                formControlName="text"
                                (click)="editItem(i, item, ref)"
                                (focus)="onFocusDraggable(item)"
                                (blur)="onBlurDraggable(item)"
                                (focusout)="clearEditIndex(i)"
                            />
                            <mat-error>{{
                                'SURVEY-EDITOR.SHARED.CHOICE.TYPE-OPTIONS'
                                    | translate
                            }}</mat-error>
                        </mat-form-field>
                        <cb-html-options
                            #ref
                            [index]="i"
                            [form]="form"
                            [pageId]="pageId"
                            [surveyId]="surveyId"
                            [hideHtml]="hideHtml"
                        ></cb-html-options>
                        <div class="merge-cell cell">
                            <cb-merge-text-list
                                *ngIf="questionType !== surveyQuestions.SLIDER"
                                [surveyId]="surveyId"
                                [pageId]="pageId"
                                [text]="editorText"
                                (updateText)="onMergeDefaultText($event, i)"
                            >
                            </cb-merge-text-list>
                        </div>

                        <mat-form-field
                            class="alias-cell cell"
                            floatLabel="never"
                        >
                            <mat-label>{{
                                    'SURVEY-EDITOR.SHARED.CHOICE.ALIAS-TEXT'
                                        | translate
                                }}</mat-label>
                            <input
                                matInput
                                (focus)="onFocusDraggable(item)"
                                (blur)="onBlurDraggable(item)"
                                formControlName="alias"
                            />
                        </mat-form-field>

                        <mat-form-field
                            class="points-cell cell"
                            floatLabel="never"
                            *ngIf="questionType !== surveyQuestions.RATINGSCALE"
                        >
                            <mat-label>{{
                                    'SURVEY-EDITOR.SHARED.CHOICE.POINTS'
                                        | translate
                                }}</mat-label>
                            <input
                                matInput
                                type="number"
                                (focus)="onFocusDraggable(item)"
                                (blur)="onBlurDraggable(item)"
                                formControlName="points"
                            />
                        </mat-form-field>

                        <div
                            *ngIf="!isRatingScale"
                            class="actions-cell cell"
                        >
                            <button
                                mat-icon-button
                                (click)="toggleChoiceVisibility(i)"
                            >
                                <i class="material-icons icon"
                                    matTooltip="{{ 'SHARED.HIDE' | translate }}"
                                    *ngIf="item.value.enabled">
                                        visibility
                                </i>
                                <i class="material-icons icon"
                                    matTooltip="{{ 'SHARED.SHOW' | translate }}"
                                    *ngIf="!item.value.enabled">
                                        visibility_off
                                </i>
                            </button>
                        </div>

                        <div class="actions-cell cell">
                            <button
                                mat-icon-button
                                (click)="onRemoveOptionClick(i)"
                            >
                                <cb-svg-icon
                                    class="icon"
                                    [name]="'close'"
                                ></cb-svg-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </ng-container>
        <a
            class="cb-button-link"
            color="primary"
            type="button"
            (click)="addOption()"
            *ngIf="!useQuickEnter"
            >{{ 'SURVEY-EDITOR.SHARED.CHOICE.ADD-CHOICE' | translate }}</a
        >
        <a
            *ngIf="questionType !== surveyQuestions.RATINGSCALE"
            class="cb-button-link"
            color="primary"
            type="button"
            (click)="toggleQuickChoices()"
            >{{
                useQuickEnter
                    ? ('SURVEY-EDITOR.SHARED.CHOICE.QUICK-BACK' | translate)
                    : ('SURVEY-EDITOR.SHARED.CHOICE.QUICK-ENTER' | translate)
            }}</a
        >
    </form>
</mat-expansion-panel>
