import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { SurveyFolder } from '../../../../models';
import { FoldersProvider } from '../../../../providers';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { AutoUnsubscribe } from '../../../../../shared/decorators/autoUnsubscribe.decorator';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'cb-move-surveys-to-folder-dialog',
    templateUrl: './move-surveys-to-folder-dialog.component.html',
    styleUrls: ['./move-surveys-to-folder-dialog.component.scss']
})
@AutoUnsubscribe()
export class MoveSurveysToFolderDialogComponent implements OnInit, OnDestroy {
    public form: UntypedFormGroup;
    folders: SurveyFolder[];
    isLoading: boolean;
    private componentDestroyed = new Subject();

    constructor(
        private fb: UntypedFormBuilder,
        private folderProvider: FoldersProvider,
        private dialogRef: MatDialogRef<MoveSurveysToFolderDialogComponent>
    ) { }

    ngOnInit() {
        this.isLoading = true;
        this.folderProvider.loadFolders()
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(folders => {
                this.folders = folders;
                this.isLoading = false;
            }, error => this.errorHandler(error));


        this.form = this.fb.group({
            survey_folder_id: [-1]
        });
    }

    cancel() {
        this.dialogRef.close({move: false });
    }

    move(): void {
        const data = this.form.value;
        if (data.survey_folder_id === -1) {
            data.survey_folder_id = null;
        }
        this.dialogRef.close({move: true, folderId: data.survey_folder_id, folderName: _.find(this.folders, { id: data.survey_folder_id })?.name });
    }

    private errorHandler(error) {
        this.isLoading = false;
    }

    ngOnDestroy() {}
}
