<div class="cb-new-question-dialog-content dialog-content">
    <form [formGroup]="form" autocomplete="off">
        <div class="form-section">
            <div class="form-section-title-wrapper">
                <h3 class="form-section-title">{{ 'SURVEY-EDITOR.ITEMS.CONTACT-FORM.FORM-FIELD' | translate }}</h3>
            </div>
            <div class="cb-change-type-section">
                <cb-item-type [itemType]="questionItem.item_type"></cb-item-type>
            </div>
            <cb-question-and-subtext
                [questionItem]="questionItem"
                [isSubquestion]="true"
            ></cb-question-and-subtext>
        </div>
        <cb-behavior-options
            [parentForm]="form"
            [softRequiredEnabled]="options.softRequiredEnabled"
            [expanded]="true"
            [isContactFormField]="true"
        >
            <div class="form-fields-group">
                <mat-form-field class="cb-form-field">
                    <mat-label>{{
                            'SURVEY-EDITOR.SHARED.ANSWER-FORMAT' | translate
                        }}</mat-label>
                    <mat-select
                        formControlName="answer_format"
                        (selectionChange)="onAnswerFormatChanged($event)"
                    >

                        <mat-option *ngFor="let type of supportedAnswerFormats" [value]="type[0]">{{
                            'SURVEY-EDITOR.ITEMS.SINGLELINES.'+type[1] | translate
                        }}</mat-option>

                    </mat-select>
                </mat-form-field>
                <div
                    class="cb-form-field"
                    *ngIf="isNumericMinMax(answerType)"
                >
                    <mat-form-field>
                        <mat-label>{{
                                'SURVEY-EDITOR.ITEMS.SINGLELINES.MIN-VALUE'
                                    | translate
                            }}</mat-label>
                        <input
                            matInput
                            autocomplete="off"
                            (keypress)="onNumericKeyUp($event)"
                            formControlName="min_value"
                        />
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label>{{
                                'SURVEY-EDITOR.ITEMS.SINGLELINES.MAX-VALUE'
                                    | translate
                            }}</mat-label>
                        <input
                            matInput
                            autocomplete="off"
                            (keypress)="onNumericKeyUp($event)"
                            formControlName="max_value"
                        />
                    </mat-form-field>
                </div>
                <div
                    class="cb-form-field"
                    *ngIf="answerType === 'Regex'"
                >
                    <mat-form-field>
                        <mat-label>{{
                                'SURVEY-EDITOR.ITEMS.SINGLELINES.PATTERN'
                                    | translate
                            }}</mat-label>
                        <input
                            matInput
                            autocomplete="off"
                            type="string"
                            formControlName="regex_pattern"
                        />
                    </mat-form-field>
                </div>
                <div
                    class="cb-form-field"
                    *ngIf="answerType === 'Regex'"
                >
                    <mat-form-field>
                        <mat-label>{{
                                'SURVEY-EDITOR.ITEMS.SINGLELINES.VALIDATION-MESSAGE'
                                    | translate
                            }}</mat-label>
                        <input
                            matInput
                            autocomplete="off"
                            type="string"
                            formControlName="regex_validation_message"
                        />
                    </mat-form-field>
                </div>

                <mat-form-field
                    class="cb-form-field"
                    *ngIf="!isNumericMinMax(answerType)"
                >
                    <mat-label>{{
                            'SURVEY-EDITOR.ITEMS.SINGLELINES.MAX-LENGTH'
                                | translate
                        }}</mat-label>
                    <input
                        matInput
                        type="number"
                        formControlName="max_length"
                    />
                </mat-form-field>
            </div>
            <div class="form-fields-group">
                <mat-form-field class="cb-form-field">
                    <mat-label>{{
                            'SURVEY-EDITOR.SHARED.DEFAULT-TEXT' | translate
                        }}</mat-label>
                    <input
                        matInput
                        formControlName="default_text"
                    />
                </mat-form-field>
                <cb-merge-text-list
                    [surveyId]="questionItem.surveyId"
                    [pageId]="questionItem.page_id"
                    [text]="editorText"
                    (updateText)="onMergeDefaultText($event)"
                >
                </cb-merge-text-list>
            </div>
        </cb-behavior-options>
        <div class="form-section">
            <cb-question-collapsed-panel
                title="{{ 'SURVEY-EDITOR.SHARED.CONDITIONS' | translate }}"
                [tooltip]="'SURVEY-EDITOR.SHARED.TOOLTIPS.CONDITIONS' | translate"
            >
                <cb-groups-expressions-element
                    [source]="questionItem"
                    [isSubquestion]="true"
                    [parentQuestionId]="contactFormItemId"
                ></cb-groups-expressions-element>
            </cb-question-collapsed-panel>
        </div>
    </form>

    <div *ngFor="let message of validationMessages" class="invalid">
        <strong>{{ message.validationMessage }}</strong>
    </div>

    <div class="buttons-container large-button-container">
        <button mat-button type="button" class="btn-cancel" (click)="cancel()">
            {{ 'SHARED.CANCEL' | translate }}
        </button>
        <button
            mat-raised-button
            type="submit"
            class="btn-submit"
            (click)="onQuestionItemSaveClick()"
            [disabled]="isSaveButtonClicked"
        >
            {{ 'SHARED.SAVE' | translate }}
        </button>
    </div>
</div>


