<form [formGroup]="form">
    <div class="form-section">
        <cb-question-item-title></cb-question-item-title>
        <cb-question-and-subtext
            [questionItem]="questionItem"
            [isMatrix]="true"
        ></cb-question-and-subtext>
    </div>

    <cb-behavior-options [parentForm]="form" [softRequiredEnabled]="questionItem.softRequiredEnabled"  [expanded]="true">
        <div class="form-fields-group">
            <mat-form-field>
                <mat-label>{{
                        'SURVEY-EDITOR.ITEMS.SUM-TOTAL.ANSWER-FORMAT'
                            | translate
                    }}</mat-label>
                <mat-select
                    formControlName="answer_format"
                    [value]="questionItem.answer_format"
                >
                    <mat-option value="Decimal">{{
                        'SURVEY-EDITOR.ITEMS.SUM-TOTAL.DECIMAL' | translate
                    }}</mat-option>
                    <mat-option value="Integer">{{
                        'SURVEY-EDITOR.ITEMS.SUM-TOTAL.INETEGER' | translate
                    }}</mat-option>
                    <mat-option value="Money">{{
                        'SURVEY-EDITOR.ITEMS.SUM-TOTAL.MONEY' | translate
                    }}</mat-option>
                    <mat-option value="Numeric">{{
                        'SURVEY-EDITOR.ITEMS.SUM-TOTAL.NUMERIC-ONLY'
                            | translate
                    }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field class="cb-form-field">
                <mat-label>{{
                        'SURVEY-EDITOR.ITEMS.SUM-TOTAL.MIN-VALUE'
                            | translate
                    }}</mat-label>
                <input
                    matInput
                    type="number"
                    formControlName="min_value"
                />
            </mat-form-field>
            <mat-form-field class="cb-form-field">
                <mat-label>{{
                        'SURVEY-EDITOR.ITEMS.SUM-TOTAL.MAX-VALUE'
                            | translate
                    }}</mat-label>
                <input
                    matInput
                    type="number"
                    formControlName="max_value"
                />
            </mat-form-field>
        </div>
        <div class="form-fields-group">
            <mat-form-field>
                <mat-label>{{
                        'SURVEY-EDITOR.SHARED.DEFAULT-TEXT' | translate
                    }}</mat-label>
                <input
                    matInput
                    formControlName="default_text"
                />
            </mat-form-field>
            <cb-merge-text-list
                [surveyId]="questionItem.surveyId"
                [pageId]="questionItem.page_id"
                [text]="editorText"
                (updateText)="onMergeDefaultText($event)"
            >
            </cb-merge-text-list>
        </div>
        <div class="form-fields-group">
            <mat-form-field>
                <mat-label>{{
                        'SURVEY-EDITOR.SHARED.OPERATOR' | translate
                    }}</mat-label>
                <mat-select
                    formControlName="operator"
                    [value]="questionItem.operator"
                >
                    <mat-option value="Equal">{{
                        'SURVEY-EDITOR.ITEMS.SUM-TOTAL.EQUAL' | translate
                    }}</mat-option>
                    <mat-option value="GreaterThan">{{
                        'SURVEY-EDITOR.ITEMS.SUM-TOTAL.GREATER' | translate
                    }}</mat-option>
                    <mat-option value="GreaterThanEqual">{{
                        'SURVEY-EDITOR.ITEMS.SUM-TOTAL.GREATER-EQUAL'
                            | translate
                    }}</mat-option>
                    <mat-option value="LessThan">{{
                        'SURVEY-EDITOR.ITEMS.SUM-TOTAL.LESS' | translate
                    }}</mat-option>
                    <mat-option value="LessThanEqual">{{
                        'SURVEY-EDITOR.ITEMS.SUM-TOTAL.LESS-EQUAL'
                            | translate
                    }}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field>
                <mat-label>{{
                        'SURVEY-EDITOR.ITEMS.SUM-TOTAL.SUM' | translate
                    }}</mat-label>
                <input
                    matInput
                    type="number"
                    formControlName="total_value"
                />
            </mat-form-field>
        </div>
    </cb-behavior-options>
</form>
