<cb-spinner [show]="!page.items"></cb-spinner>
<div class="cb-page-edit-toolbar" *ngIf="page.items">
    <button
        mat-button
        *ngIf="page.page_type !== surveyPageType.Completion"
        class="cb-button-default"
        [matMenuTriggerFor]="questionMenu"
        (click)="onOpenSurveyQuestionsDialog('questionItems')"
    >
        <mat-icon class="icon">help</mat-icon>
        {{ 'SURVEY-ITEM-LIST.ADD-SURVEY-QUESTION' | translate }}
    </button>
    <button
        mat-button
        class="cb-button-default"
        [matMenuTriggerFor]="questionMenu"
        (click)="onOpenSurveyQuestionsDialog('displayItems')"
    >
        <mat-icon class="icon">image</mat-icon>
        {{ 'SURVEY-ITEM-LIST.ADD-DISPLAY-ITEM' | translate }}
    </button>
    <button
        mat-button
        class="cb-button-default"
        [matMenuTriggerFor]="questionMenu"
        (click)="onOpenSurveyQuestionsDialog('reportItems')"
    >
        <mat-icon class="icon">pie_chart</mat-icon>
        {{ 'SURVEY-ITEM-LIST.ADD-REPORT-ITEM' | translate }}
    </button>
    <button
        mat-button
        class="cb-button-default"
        [matMenuTriggerFor]="questionMenu"
        (click)="onOpenSurveyQuestionsDialog('actionItems')"
    >
        <mat-icon class="icon">flash_on</mat-icon>
        {{ 'SURVEY-ITEM-LIST.ADD-ACTION-ITEM' | translate }}
    </button>
    <button
        *ngIf="!isSurveyTemplate"
        mat-button
        class="cb-button-default"
        (click)="openFromTemplateDialog()"
    >
        <mat-icon class="icon">book</mat-icon>
        {{ 'SURVEY-ITEM-LIST.ADD-FROM-TEMPLATE' | translate }}
    </button>
</div>

<mat-menu
    #questionMenu="matMenu"
    [overlapTrigger]="false"
    class="action-button-menu-panel"
    xPosition="after"
>
    <cb-menu-dialog
        [itemType]="itemType"
        [page]="page"
        [surveyId]="surveyId"
        [pageTitles]="pageTitles"
        [allowJavascript]="allowJavascript"
        [allowFileUpload]="allowFileUpload"
        [allowWebhookItem]="allowWebhookItem"
        [showInvisibleCaptchaQuestion]="showInvisibleCaptchaQuestion"
        [showVisibleCaptchaQuestion]="showVisibleCaptchaQuestion"
        [isSurveyTemplate]="isSurveyTemplate"
        [softRequiredEnabled]="softRequiredEnabled"
    ></cb-menu-dialog>
</mat-menu>
