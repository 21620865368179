<cb-main-dialog
    [title]="'SURVEYS-LIST.MOVE-TO-FOLDER' | translate"
    [mainButton]="'SURVEYS-LIST.MOVE' | translate"
    [cancelButton]="'SHARED.CANCEL' | translate"
    (onMain)="move()"
    (onCancel)="cancel()">
    <cb-spinner [blockLayout]="true" [show]="true" *ngIf="isLoading"></cb-spinner>
    <form [formGroup]="form" novalidate name="confirmationForm" *ngIf="!isLoading">
        <div>
            <label>{{ 'NEW-SURVEY-DIALOG.SELECT_FOLDER' | translate }}</label>
            <mat-form-field class="no-top no-label dialog-form" appearance="outline" floatLabel="never">
                <mat-label>{{
                        'NEW-SURVEY-DIALOG.SELECT_FOLDER' | translate
                    }}</mat-label>
                <mat-select
                    formControlName="survey_folder_id"
                >
                    <mat-option [value]="-1">{{
                        'NEW-SURVEY-DIALOG.NO_FOLDER' | translate
                    }}</mat-option>
                    <mat-option *ngFor="let folder of folders" [value]="folder.id">
                        {{ folder.name }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </form>
</cb-main-dialog>

