import { MatDialog } from '@angular/material/dialog';
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SharedService } from '../../../../../../infrastructure/services';
import { SurveyQuestionType } from '../../../../../../infrastructure/consts/surveys.consts';
import { MatrixRowExpressionComponent } from '../../../related-components/matrix-row-dialog/matrix-row-expression.component';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AutoUnsubscribe } from '../../../../../../shared/decorators/autoUnsubscribe.decorator';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);

@Component({
    selector: 'cb-matrix-rows-item',
    templateUrl: './matrix-rows-item.component.html',
    styleUrls: ['./matrix-rows-item.component.scss']
})
@AutoUnsubscribe()
export class MatrixRowsItemComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChildren('optionsElement') optionsElement: QueryList<ElementRef>;
    @Input() options: any[];
    @Input() questionType: any;
    @Input() expanded = false;

    isIEOrEdge: boolean;

    checkBoxes = SurveyQuestionType.CHECKBOXES;
    radiobuttons = SurveyQuestionType.RADIOBUTTONS;
    dropdownlist = SurveyQuestionType.DROPDOWNLIST;
    rating = SurveyQuestionType.RATING;
    matrix = SurveyQuestionType.MATRIX;

    dragAndDrop: any;
    form: UntypedFormGroup;
    items: any;
    private componentDestroyed = new Subject();

    constructor(
        private fb: UntypedFormBuilder,
        private sharedService: SharedService,
        private dialog: MatDialog
    ) {
        this.isIEOrEdge = isIEOrEdge;
    }

    ngOnInit() {
        this.form = this.fb.group({
            options: this.fb.array([])
        });

        this.form.valueChanges
            .pipe(
                takeUntil(this.componentDestroyed)
            )
            .subscribe(s =>
                this.sharedService.share('matrixRows', s)
            );
        if (this.options) {
            this.options.sort(function(a, b) {
                return a.position - b.position;
            });
            this.options.forEach(element => {
                const control = new UntypedFormGroup({
                    id: new UntypedFormControl(element.id),
                    is_default: new UntypedFormControl(element.is_default),
                    text: new UntypedFormControl(element.text),
                    alias: new UntypedFormControl(element.alias),
                    position: new UntypedFormControl(element.position),
                    row_type: new UntypedFormControl(element.row_type),
                    include_condition: new UntypedFormControl(
                        element.include_condition
                    )
                });
                (<UntypedFormArray>this.form.get('options')).push(control);
            });
        }

        // Create an empty row
        this.addOption('Normal');

        this.items = this.form.get('options')['controls'];

        this.dragAndDrop = {
            onUpdate: (event: any) => {
                this.items.forEach((c, i) => {
                    c['controls'].position.setValue(i);
                });
            }
        };
    }

    onDrop(event: CdkDragDrop<any[]>) {
        moveItemInArray(this.items, event.previousIndex, event.currentIndex);
        this.updatePositions();
    }

    updatePositions() {
        this.items.forEach((c, i) => {
            c['controls'].position.setValue(i);
        });
    }

    ngAfterViewInit() {
        this.optionsElement.changes.subscribe(options => {
            if (options.last && !isIEOrEdge) {
                options.last.nativeElement.focus();
            } else if (options.last) {
                setTimeout(function() {
                    options.last.nativeElement.focus();
                }, 1);
            }
        });
    }

    private onAddExpressions(index) {
        const item = _.mapValues(this.items[index].controls, x => x.value);
        item.surveyId = this.questionType.surveyId;
        item.page_id = this.questionType.page_id;
        const dialogRef = this.dialog.open(MatrixRowExpressionComponent, {
            width: '1024px',
            data: { row: item }
        });
        dialogRef.afterClosed().subscribe(data => {
            if (data) {
                (<UntypedFormArray>this.form.get('options')).controls[
                    index
                ].patchValue({
                    include_condition: data
                });
            }
        });
    }

    public addOption(row_type): void {
        const index = (<UntypedFormArray>this.form.get('options')).length + 1;
        const control = new UntypedFormGroup({
            is_default: new UntypedFormControl(false),
            text: new UntypedFormControl(null),
            alias: new UntypedFormControl(null),
            position: new UntypedFormControl(index),
            row_type: new UntypedFormControl(row_type),
            include_condition: new UntypedFormControl(null)
        });
        (<UntypedFormArray>this.form.get('options')).push(control);
    }

    isRowTypeOther(i) {
        return this.form.get('options')['controls'][i].get('row_type').value === 'Other';
    }

    public onRemoveOptionClick(index): void {
        const options = <UntypedFormArray>this.form.get('options');
        options.removeAt(index);
        this.updatePositions();
    }

    public onEnter(isDefaultInput = true) {
        if (isDefaultInput) {
            this.addOption('Normal');
        }
        return false;
    }

    onMergeDefaultText(updatedText, index) {
        const options: any = this.form.get('options');
        const field = options.controls[index].get('text');
        field.setValue(`${field.value || ''} ${updatedText}`);
    }

    ngOnDestroy() {}
}
