<div class="dialog-content">
    <h2 mat-dialog-title>{{ 'SURVEY-TEMPLATES.SAVE-AS-TEMPLATE' | translate }}</h2>
    <cb-spinner *ngIf="isLoading" [show]="true"></cb-spinner>
    <form
        [formGroup]="form"
        (ngSubmit)="onSubmit()"
        novalidate
        *ngIf="!isLoading"
    >
        <mat-form-field class="full-width-input">
            <mat-label>{{
                    'SURVEY-TEMPLATES.NAME' | translate
                }}</mat-label>
            <input
                matInput
                formControlName="name"
                maxlength="255"
            />
        </mat-form-field>
        <mat-form-field class="full-width-input">
            <mat-label>{{
                    'SURVEY-TEMPLATES.DESCRIPTION' | translate
                }}</mat-label>
                <input
                matInput
                formControlName="description"
            />
        </mat-form-field>
        <div class="buttons-container">
            <button
                mat-button
                type="button"
                mat-dialog-close
                class="btn-cancel"
            >
                {{ 'SHARED.CANCEL' | translate }}
            </button>
            <button
                mat-raised-button
                color="danger"
                class="btn-submit"
                type="submit"
                [disabled]="disableSubmitButton()"
            >
                {{ 'SHARED.SAVE' | translate }}
            </button>
        </div>
    </form>
</div>
