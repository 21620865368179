<form [formGroup]="form" autocomplete="off">
    <div class="form-section">
        <cb-question-item-title></cb-question-item-title>
        <div class="cb-change-type-section">
            <cb-item-type [itemType]="netPromoteScore"></cb-item-type>
        </div>
        <cb-question-and-subtext
            [questionItem]="questionItem"
        ></cb-question-and-subtext>
    </div>
    <cb-behavior-options [parentForm]="form" [softRequiredEnabled]="questionItem.softRequiredEnabled"  [expanded]="true">
    </cb-behavior-options>
    <div class="form-section border-top">
        <cb-question-collapsed-panel
            title="{{
                'SURVEY-EDITOR.ITEMS.ACTION-ITEMS.APPEARANCE' | translate
            }}"
        >
            <div class="form-fields-group">
                <mat-form-field class="cb-form-field">
                    <mat-label>{{
                            'SURVEY-EDITOR.ITEMS.NET-PROMOTER.LAYOUT'
                                | translate
                        }}</mat-label>
                    <mat-select
                        formControlName="layout"
                    >
                        <mat-option value="Vertical">{{
                            'SURVEY-EDITOR.ITEMS.NET-PROMOTER.VERTICAL'
                                | translate
                        }}</mat-option>
                        <mat-option value="Horizontal">{{
                            'SURVEY-EDITOR.ITEMS.NET-PROMOTER.HORIZONTAL'
                                | translate
                        }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="form-group-position">
                <cb-label-position
                    class="position-control-group"
                    [selectedOption]="textPosition"
                    (selected)="labelPositionSelected($event)"
                ></cb-label-position>
                <cb-item-position
                    class="position-control-group"
                    [selectedOption]="itemPosition"
                    (selected)="itemPositionSelected($event)"
                ></cb-item-position>
            </div>
        </cb-question-collapsed-panel>
    </div>
    <div class="form-section">
        <cb-question-collapsed-panel
            title="{{ 'SURVEY-EDITOR.SHARED.CONDITIONS' | translate }}"
            [tooltip]="'SURVEY-EDITOR.SHARED.TOOLTIPS.CONDITIONS' | translate"
        >
            <cb-groups-expressions-element
                [source]="questionItem"
            ></cb-groups-expressions-element>
        </cb-question-collapsed-panel>
    </div>
    <cb-wcag-alert></cb-wcag-alert>
</form>
