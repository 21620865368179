import { Component, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import {
    UntypedFormGroup,
    UntypedFormBuilder,
    Validators,
    UntypedFormControl,
    AbstractControl,
    ValidatorFn,
    ValidationErrors
} from '@angular/forms';
import { LoginProvider } from '../../../infrastructure/providers/login.provider';
import { EditMyInfoProvider } from '../../../infrastructure/providers';
import { ProfileProperty } from '../../../infrastructure/models/profile-property.model';
import { RegistrationSettings } from '../../..//infrastructure/models/registration-settings.model';
import { PasswordService, ToasterService } from '../../../infrastructure/services';
import { Contact, EditCurrentContactModel, CreateProfilePropertyModel } from '../../../infrastructure/models/contact.model';
import { forkJoin, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { AutoUnsubscribe } from '../../../shared/decorators/autoUnsubscribe.decorator';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'cb-edit-my-info',
    templateUrl: './edit-my-info.component.html',
    styleUrls: ['./edit-my-info.component.scss']
})
@AutoUnsubscribe()
export class EditMyInfoComponent implements OnInit, OnDestroy {
    public isLoading: boolean;
    public allowEdit: boolean = true;
    public showSmsPhone: boolean = false;
    public isAdContact: boolean = false;
    public overrideAdMapping: boolean;
    public form: UntypedFormGroup;
    public validationMessage: string;
    public propertyNames: string[] = [];
    private componentDestroyed = new Subject();
    private successMsg = 'User information successfully saved';
    private userLanguage: string = null;

    public get passwordValidationError(): string {
        return this.passwordService.getErrorMessage();
    }

    @Output() register: EventEmitter<any> = new EventEmitter();

    constructor(
        private fb: UntypedFormBuilder,
        private editMyInfoProvider: EditMyInfoProvider,
        private toasterService: ToasterService,
        private loginProvider: LoginProvider,
        private passwordService: PasswordService,
        private translateService: TranslateService
    ) {}

    ngOnInit() {
        this.isLoading = true;

        this.translateService
            .get('EDIT-MY-INFO.SUCCESSFULLY-SAVED')
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe((data: string) => {
                this.successMsg = data;
            });

        forkJoin([
            this.editMyInfoProvider.getCurrentUserInfo(),
            this.loginProvider.getUserProfileProperties(),
            this.loginProvider.getRegistrationSettings()
        ])
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(([curUser, properties, regSettings]: [Contact, ProfileProperty[], RegistrationSettings]) => {
                this.isAdContact = curUser.id.includes('\\');
                this.allowEdit = !this.isAdContact || curUser.override_active_directory_mapping;
                this.overrideAdMapping = curUser.override_active_directory_mapping;
                this.showSmsPhone = regSettings == null || !regSettings.hide_sms_phone;

                this.form = this.fb.group({
                    current_password: ['', this.currentPasswordRequiredValidator()],
                    password: [''],
                    confirm_password: ['', this.confirmPasswordValidator()],
                    email: [
                        { value: curUser.email, disabled: this.isAdContact || !this.allowEdit },
                        curUser.email ? Validators.required : null
                    ],
                    phoneSms: [{ value: curUser.phone_sms, disabled: this.isAdContact || !this.allowEdit }]
                });

                this.addProfilePropertiesControls(curUser, properties);

                if (!this.isAdContact) {
                    this.passwordService.setValidators(this.form.controls['password'], false);
                    this.form.get('password').valueChanges
                        .pipe(
                            takeUntil(this.componentDestroyed),
                            debounceTime(500),
                            distinctUntilChanged()
                        )
                        .subscribe(password => {
                            this.form.controls['current_password'].updateValueAndValidity();
                            this.form.controls['confirm_password'].updateValueAndValidity();
                        });
                }
                this.isLoading = false;
            });
    }

    private addProfilePropertiesControls(curUser: Contact, properties: ProfileProperty[]) {
        properties.forEach(property => {
            if (!property.is_hidden) {
                const propertyValue = curUser.profile_properties.find(p => p.property_id == property.id)?.value || '';
                this.form.addControl(
                    property.name,
                    new UntypedFormControl({ value: propertyValue, disabled: !this.allowEdit})
                );
                this.propertyNames.push(property.name);

                if (this.isLanguageProperty(property.name))
                    this.userLanguage = propertyValue;
            }
        });
    }

    private isLanguageProperty(propertyName: string): boolean {
        return propertyName && propertyName.toLowerCase() == 'applicationlanguage';
    }

    onSubmit() {
        const formData = this.form.value;
        if (this.form.valid) {
            const userData = new EditCurrentContactModel();
            let isLanguageChanged = false;
            userData.current_password = formData.current_password;
            userData.password = formData.password;
            userData.email = formData.email;
            userData.phone_sms = formData.phoneSms;
            userData.profile_properties = [];

            for (const key in formData) {
                if (formData.hasOwnProperty(key)) {
                    if (this.propertyNames.includes(key)) {
                        userData.profile_properties.push(
                            new CreateProfilePropertyModel({
                                property_name: key,
                                value: formData[key]
                            })
                        );

                        if (this.isLanguageProperty(key)) {
                            isLanguageChanged = (this.userLanguage || '') != (formData[key] || '');
                        }
                    }
                }
            }

            this.editMyInfoProvider.editCurrentUserInfo(userData).subscribe(user => {
                setTimeout(() => {
                    this.toasterService.showInfo(this.successMsg);
                }, 500);

                if (isLanguageChanged) {
                    setTimeout(() => { window.location.reload(); }, 3000);
                }
            });
        }
    }

    private confirmPasswordValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!this.form) {
                return null;
            }

            const password = this.form.get('password').value;
            const passwordConfirmation = control.value;

            return password && password !== passwordConfirmation
                ? { confirmPassword: true }
                : null;
        };
    }

    private currentPasswordRequiredValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors  | null => {
            const curPassword = control.value;
            return this.form && this.form.get('password').value && !curPassword
                ? { currentPasswordRequired: true }
                 : null;
        };
    }

    ngOnDestroy() {}
}
