<form [formGroup]="form" autocomplete="off">
    <div class="form-section">
        <cb-question-item-title></cb-question-item-title>
        <div class="cb-change-type-section">
            <cb-item-type [itemType]="slider"></cb-item-type>
        </div>
        <cb-question-and-subtext
            [questionItem]="questionItem"
            [isMatrix]="isMatrix"
        ></cb-question-and-subtext>
    </div>
    <cb-behavior-options [parentForm]="form" [softRequiredEnabled]="false" [expanded]="true">
        <div class="form-fields-group">
            <mat-form-field class="cb-form-field">
                <mat-label>{{
                        'SURVEY-EDITOR.ITEMS.SLIDER.VALUE-TYPE' | translate
                    }}</mat-label>
                <mat-select
                    formControlName="value_type"
                    [value]="questionItem.value_type"
                    #value_type
                >
                    <mat-option value="NumberRange">{{
                        'SURVEY-EDITOR.SHARED.CHOICE.NUMBER-RANGE'
                            | translate
                    }}</mat-option>
                    <mat-option value="Image" *ngIf="!isMatrix">{{
                        'SURVEY-EDITOR.SHARED.CHOICE.IMAGE' | translate
                    }}</mat-option>
                    <mat-option value="Text">{{
                        'SURVEY-EDITOR.SHARED.CHOICE.TEXT' | translate
                    }}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div
            class="form-fields-group"
            *ngIf="!value_type.value || value_type.value === 'NumberRange'"
        >
            <mat-form-field class="cb-form-field">
                <mat-label>{{
                        'SURVEY-EDITOR.ITEMS.SLIDER.MIN-VALUE' | translate
                    }}</mat-label>
                <input
                    matInput
                    type="number"
                    (keypress)="onNumericKeyUp($event)"
                    formControlName="min_value"
                    required
                />
            </mat-form-field>
            <mat-form-field class="cb-form-field">
                <mat-label>{{
                        'SURVEY-EDITOR.ITEMS.SLIDER.MAX-VALUE' | translate
                    }}</mat-label>
                <input
                    matInput
                    type="number"
                    (keypress)="onNumericKeyUp($event)"
                    formControlName="max_value"
                    required
                />
            </mat-form-field>
            <mat-form-field class="cb-form-field">
                <mat-label>{{
                        'SURVEY-EDITOR.ITEMS.SLIDER.STEP-SIZE' | translate
                    }}</mat-label>
                <input
                    matInput
                    type="number"
                    (keypress)="onNumericKeyUp($event)"
                    formControlName="step_size"
                    required
                />
            </mat-form-field>
            <mat-form-field class="cb-form-field">
                <mat-label>{{
                        'SURVEY-EDITOR.ITEMS.SLIDER.DEFAULT-VALUE'
                            | translate
                    }}</mat-label>
                <input
                    matInput
                    type="number"
                    (keypress)="onNumericKeyUp($event)"
                    formControlName="value"
                />
            </mat-form-field>
        </div>
    </cb-behavior-options>

    <div class="form-section border-top">
        <cb-multiple-options-image-item
            *ngIf="value_type.value === 'Image'"
            [options]="questionItem.choices"
            [questionType]="slider"
            [resetChoicesEvent] = "resetChoicesSubject.asObservable()"
        ></cb-multiple-options-image-item>
        <cb-multiple-options-item
            *ngIf="value_type.value === 'Text'"
            [surveyId]="questionItem.surveyId"
            [pageId]="questionItem.page_id"
            [options]="questionItem.choices"
            [questionType]="slider"
            [resetChoicesEvent] = "resetChoicesSubject.asObservable()"
            [isMatrix]="isMatrix"
            [isSurveyTemplate] = "isSurveyTemplate"
        >
        </cb-multiple-options-item>
        <p *ngIf="value_type.value !== 'Image' && value_type.value !== 'Text'">
            <strong *ngIf="!isMatrix"
                >{{ 'SURVEY-EDITOR.ITEMS.SLIDER.TEXT' | translate }}
            </strong>
            <strong *ngIf="isMatrix"
                >{{
                    'SURVEY-EDITOR.ITEMS.SLIDER.TEXT-WITHOUT-IMAGE' | translate
                }}
            </strong>
        </p>
    </div>
    <div class="form-section">
        <cb-question-collapsed-panel
            title="{{ 'SURVEY-EDITOR.ITEMS.SLIDER.APPEARANCE' | translate }}"
        >
            <div class="form-fields-group">
                <mat-form-field class="cb-form-field" *ngIf="!isMatrix">
                    <mat-label>{{
                            'SURVEY-EDITOR.ITEMS.SLIDER.WIDTH' | translate
                        }}</mat-label>
                    <input
                        matInput
                        type="number"
                        (keypress)="onNumericKeyUp($event)"
                        formControlName="width"
                    />
                </mat-form-field>
                <mat-checkbox
                    class="cb-form-field"
                    formControlName="show_value"
                    *ngIf="value_type.value === 'NumberRange'"
                    >{{ 'SURVEY-EDITOR.ITEMS.SLIDER.SHOW-VALUE' | translate }}
                </mat-checkbox>
            </div>
            <div class="form-group-position">
                <cb-label-position
                    class="position-control-group"
                    [selectedOption]="textPosition"
                    (selected)="onLabelPositionSelected($event)"
                ></cb-label-position>
                <cb-item-position
                    class="position-control-group"
                    [selectedOption]="itemPosition"
                    (selected)="onItemPositionSelected($event)"
                ></cb-item-position>
            </div>
            <div class="form-fields-group" *ngIf="value_type.value === 'Image'">
                <mat-form-field class="cb-form-field">
                    <mat-label>{{
                            'SURVEY-EDITOR.ITEMS.SLIDER.ALIAS-POSITION'
                                | translate
                        }}</mat-label>
                    <mat-select
                        formControlName="alias_position"
                        [value]="questionItem.alias_position"
                    >
                        <mat-option value="DontShow">{{
                            'SURVEY-EDITOR.ITEMS.SLIDER.DONT-SHOW' | translate
                        }}</mat-option>
                        <mat-option value="Top">{{
                            'SURVEY-EDITOR.ITEMS.SLIDER.TOP' | translate
                        }}</mat-option>
                        <mat-option value="Bottom">{{
                            'SURVEY-EDITOR.ITEMS.SLIDER.BOTTOM' | translate
                        }}</mat-option>
                    </mat-select>
                </mat-form-field>
                <mat-form-field class="cb-form-field">
                    <mat-label>{{
                            'SURVEY-EDITOR.ITEMS.SLIDER.IMAGE-POSITION'
                                | translate
                        }}</mat-label>
                    <mat-select
                        formControlName="image_position"
                        [value]="questionItem.image_position"
                    >
                        <mat-option value="Top">{{
                            'SURVEY-EDITOR.ITEMS.SLIDER.TOP' | translate
                        }}</mat-option>
                        <mat-option value="Bottom">{{
                            'SURVEY-EDITOR.ITEMS.SLIDER.BOTTOM' | translate
                        }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </cb-question-collapsed-panel>
    </div>
    <div class="form-section" *ngIf="!isMatrix">
        <cb-question-collapsed-panel
            title="{{ 'SURVEY-EDITOR.ITEMS.SLIDER.CONDITIONS' | translate }}"
        >
            <cb-groups-expressions-element
                [source]="questionItem"
            ></cb-groups-expressions-element>
        </cb-question-collapsed-panel>
    </div>
</form>
