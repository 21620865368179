import { Component, Input, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil, debounceTime, distinctUntilChanged, first } from 'rxjs/operators';
import { DashboardsProvider } from '../../../infrastructure/providers/dashboards/dashboards.provider';
import {
    DashboardPage,
} from '../../models/dashboards/dashboard-page.model';
import { SurveyPage } from '../../models';
import { SurveyPagesProvider } from '../../providers';
import { AutoUnsubscribe } from '../../../shared/decorators/autoUnsubscribe.decorator';

@Component({
    selector: 'cb-rename-page',
    templateUrl: './rename-page.component.html',
    styleUrls: ['./rename-page.component.scss']
})
@AutoUnsubscribe()
export class RenamePageComponent implements OnInit, OnDestroy {
    @Input() page: SurveyPage | DashboardPage;
    @Input() isDashboardPage: boolean;
    @Input() isTemplatePage: boolean;
    @Input() set isLockedInput (value: boolean) {
        this.isLocked = value;
        this.checkOnLockedInput();
    }
    @Input() sourceId: number;
    @Output() emitLockedSurveyWarning: EventEmitter<boolean> = new EventEmitter();
    form: UntypedFormGroup;
    isLocked: boolean;
    private componentDestroyed = new Subject();

    constructor(
        private fb: UntypedFormBuilder,
        private pageProvider: SurveyPagesProvider,
        private dashboardProvider: DashboardsProvider
    ) {}

    ngOnInit() {
        this.form = this.fb.group({
            name: new UntypedFormControl({value: this.page.title, disabled: this.isLocked})
        });
        this.form
            .get('name')
            .valueChanges
            .pipe(
                takeUntil(this.componentDestroyed),
                debounceTime(500),
                distinctUntilChanged()
            )
            .subscribe(n => {
                this.renamePage(n);
            });



    }

    showWarningIfLocked() {
        if (this.isLocked) {
            this.emitLockedSurveyWarning.emit();
        }
    }

    renamePage(name) {
        if (this.page.title === name) {
            // prevent sending request when survey change unlock status
            return;
        }
        this.page.title = name;
        const request = this.isDashboardPage ?
            this.dashboardProvider.updateDashboardPage(
                this.sourceId,
                this.page.id,
                <DashboardPage>this.page
            ) :
            this.pageProvider.updateSurveyPage(
                this.sourceId,
                this.page.id,
                <SurveyPage>this.page,
                this.isTemplatePage
            );
        request.pipe(
            first()
        ).subscribe();
    }

    checkOnLockedInput() {
       if (this.form) {
        this.isLocked ? this.form['controls']['name'].disable() : this.form['controls']['name'].enable();
       }
    }

    ngOnDestroy() {}
}
