import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SurveysProvider } from '../../../providers';
import { AllSurveysListComponent } from '../surveys-list/all-surveys-list/all-surveys-list.component';
import { ToasterService } from '../../../../infrastructure/services';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';

@Component({
    selector: 'cb-save-as-template-dialog',
    templateUrl: './save-as-template-dialog.component.html',
    styleUrls: ['../dialog-windows/add-dialog.scss']
})
export class SaveAsTEmplateDialogComponent implements OnInit {
    public form: UntypedFormGroup;
    survey: any;
    isLoading: boolean;
    constructor(
        private fb: UntypedFormBuilder,
        private surveysProvider: SurveysProvider,
        private dialogRef: MatDialogRef<AllSurveysListComponent>,
        private toasterService: ToasterService,
        private translateService: TranslateService,
        @Inject(MAT_DIALOG_DATA) private data
    ) {}

    ngOnInit() {
        this.survey = this.data.survey;
        this.form = this.fb.group({
            name: [this.survey.name + '_Template', Validators.required],
            description: ['']
        });
    }

    onSubmit(): void {
        this.isLoading = true;
        const { name, description } = this.form.value;
        if (this.form.valid) {
            const data = {
                template_name: name,
                template_description: description
            };
            this.surveysProvider.saveAsTemplate(this.survey.id , data).pipe(
                first()
            ).subscribe(
                () => {
                    this.translateService
                    .get('SURVEY-TEMPLATES.NEW-TEMPLARE-SAVED')
                    .subscribe((text: string) => {
                        this.toasterService.showSuccess(text, false, null, 'outline');
                    });
                    this.dialogRef.close();
                },
                error => this.errorHandler(error)
            );
        }
    }

    disableSubmitButton() {
        return !this.form.valid || this.form.value.name.trim() === '';
    }

    errorHandler(error) {
        this.isLoading = false;
    }
}
