import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { first } from 'rxjs/operators';
import { ApplicationSettingsProvider } from '../../../../../../app-admin/settings/application-settings/application-settings.provider';

@Component({
    selector: 'cb-contact-form-behavior',
    templateUrl: './contact-form-behavior.component.html',
    styleUrls: ['./contact-form-behavior.component.scss']
})

export class ContactFormBehaviorComponent implements OnInit {
    @Input() parentForm: UntypedFormGroup;
    @Input() expanded: boolean;
    showCustomCss: boolean;

    constructor(
        private applicationSettingsProvider: ApplicationSettingsProvider
    ) {}

    ngOnInit() {
        this.applicationSettingsProvider
            .getSurveyApplicationSettings()
            .pipe(first())
            .subscribe(data => {
                this.showCustomCss = data.advanced_survey_controls_settings.enable_survey_wide_css;
            });
    }
}
