import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { SurveyQuestionType } from '../../../../../infrastructure/consts/surveys.consts';
import { SharedService } from '../../../../../infrastructure/services';
import { RatingScaleItem } from '../../../../../shared/models/survey-items/question-items/ratingScaleItem';
import { SurveyEditorValidator } from '../../../../../infrastructure/validators/survey-editor.validators';
import { Subject } from 'rxjs';
import { AutoUnsubscribe } from '../../../../../shared/decorators/autoUnsubscribe.decorator';
import { takeUntil } from 'rxjs/operators';
import { isNil } from 'lodash';
import { MultipleChoicesItemComponent } from '../multiple-choices-item.component';

@Component({
    selector: 'cb-rating-scale-item',
    templateUrl: './rating-scale-item.component.html',
    styleUrls: ['./rating-scale-item.component.scss']
})
@AutoUnsubscribe()
export class RatingScaleItemComponent extends MultipleChoicesItemComponent implements OnInit, OnDestroy {
    @Input() questionItem: RatingScaleItem;
    @Input() isMatrix: boolean;

    ratingScale = SurveyQuestionType.RATINGSCALE;
    editorText = '';
    editorCursorPos: number;

    public form: UntypedFormGroup;

    itemPosition: string;
    textPosition: string;
    showTextInuts: boolean;
    private componentDestroyed = new Subject();

    constructor(
        private fb: UntypedFormBuilder,
        private sharedService: SharedService
    ) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        this.createFormGroup();
        this.createQuestionItemObject();
    }

    itemPositionSelected(value): void {
        this.itemPosition = value;
        this.form.controls['item_position'].setValue(value);
    }

    labelPositionSelected(value): void {
        this.textPosition = value;
        this.form.controls['question_text_position'].setValue(value);
    }

    private createFormGroup(): void {
        let isAllQuestionsRequired = false;
        if (!this.questionItem) {
            this.questionItem = <RatingScaleItem>{};
        }
        if (!this.questionItem.id) {
            isAllQuestionsRequired = this.sharedService.surveyRespondentSettings.getValue() ?
            this.sharedService.surveyRespondentSettings.getValue().makeQuestionsRequired : false;
        }
        this.showTextInuts = !!this.questionItem.start_text || !!this.questionItem.mid_text || !!this.questionItem.end_text;
        this.form = this.fb.group({
            // behavior
            alias: new UntypedFormControl(this.questionItem.alias),
            html_class: new UntypedFormControl(this.questionItem.html_class),
            start_text: new UntypedFormControl(this.questionItem.start_text),
            mid_text: new UntypedFormControl(this.questionItem.mid_text),
            end_text: new UntypedFormControl(this.questionItem.end_text),
            is_required: new UntypedFormControl(this.questionItem.is_required || isAllQuestionsRequired),
            is_soft_required: new UntypedFormControl(this.questionItem.softRequiredEnabled ? !!this.questionItem.is_soft_required : false),
            enable_not_applicable: new UntypedFormControl(
                !!this.questionItem.enable_not_applicable
            ),
            not_applicable_text: new UntypedFormControl(
                this.questionItem.not_applicable_text
            ),

            // appearance
            layout: new UntypedFormControl(this.questionItem.layout),
            choice_label_type: new UntypedFormControl(this.questionItem.choice_label_type ? this.questionItem.choice_label_type : 'TextAndRating'),
            item_position: new UntypedFormControl(this.questionItem.item_position),
            question_text_position: new UntypedFormControl(
                this.questionItem.question_text_position
            ),
            show_separator: new UntypedFormControl(isNil(this.questionItem.show_separator) ? true : this.questionItem.show_separator),
            option_width: new UntypedFormControl(this.questionItem.option_width)
        });
        this.itemPosition = this.questionItem.item_position;
        this.textPosition = this.questionItem.question_text_position;
    }

    private createQuestionItemObject(): void {
        this.updateQuestionItemObject();
        this.form.valueChanges
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(s => {
                this.updateQuestionItemObject();
            });
    }

    private updateQuestionItemObject(): void {
        const control = this.form.controls;
        const questionItem = new RatingScaleItem();
        questionItem.item_type = SurveyQuestionType.RATINGSCALE;
        questionItem.alias = control['alias'].value;
        questionItem.html_class = control['html_class'].value;
        questionItem.start_text = control['start_text'].value;
        questionItem.mid_text = control['mid_text'].value;
        questionItem.end_text = control['end_text'].value;
        questionItem.is_soft_required = control['is_soft_required'].value;
        questionItem.is_required = control['is_required'].value;
        questionItem.question_text_position = control['question_text_position'].value;
        questionItem.item_position = control['item_position'].value;
        questionItem.layout = control['layout'].value;
        questionItem.choice_label_type = control['choice_label_type'].value;
        questionItem.show_separator = control['show_separator'].value;
        questionItem.option_width = control['option_width'].value;
        questionItem.enable_not_applicable = control['enable_not_applicable'].value;
        questionItem.not_applicable_text = control['not_applicable_text'].value;
        this.sharedService.share('questionItemSettings', questionItem);
    }

    public onMergeText(updatedText) {
        const field = this.form.controls['not_applicable_text'];
        field.setValue(updatedText);
    }

    public onNumericKeyUp(event: any) {
        return SurveyEditorValidator.validateNumericInput(event);
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
}
