<mat-form-field
    class="merge-select-wrapper app-input-no-underline"
    (click)="clickOnWrapper()"
    title="{{ 'SURVEY-EDITOR.SHARED.MERGE' | translate }}"
>
    <mat-select
        panelWidth="100"
        class="merge-code-select"
        panelClass="merge-code-select-panel"
        (selectionChange)="onMergeToQuestionControl($event)"
        #mergeListControl
    >
        <mat-optgroup
            *ngFor="let mGroup of mergeList"
            [label]="'MERGE-CODES.' + mGroup.name | translate"
        >
            <mat-option
                *ngFor="let mItem of mGroup.merge_codes"
                [value]="mItem.code"
                >{{ getTranslation(mItem) | async }}</mat-option
            >
        </mat-optgroup>
    </mat-select>
</mat-form-field>
