import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import {
    SurveyQuestionType,
    SurveyCaptchaType
} from '../../../../../infrastructure/consts/surveys.consts';
import { SharedService } from '../../../../../infrastructure/services';
import { CaptchaItem } from '../../../../../shared/models/survey-items/question-items/captchaItem';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AutoUnsubscribe } from '../../../../../shared/decorators/autoUnsubscribe.decorator';
import { CaptchaKeys } from '../../../../../app-take-survey/models';

@Component({
    selector: 'cb-captcha-item',
    templateUrl: './captcha-item.component.html',
    styleUrls: ['./captcha-item.component.scss']
})
@AutoUnsubscribe()
export class CaptchaItemComponent implements OnInit, OnDestroy {
    @Input() questionItem: CaptchaItem;
    @Input() captchaTypes;

    public form: UntypedFormGroup;
    public captchaType = SurveyCaptchaType;
    defaultCaptchaType = SurveyCaptchaType.VISIBLE;
    defaultCaptchaTheme = 'Light';
    defaultCaptchaSize = 'Normal';
    private componentDestroyed = new Subject();
    public showInvisibleCaptchaQuestion: boolean;
    public showVisibleCaptchaQuestion: boolean;

    constructor(
        private fb: UntypedFormBuilder,
        private sharedService: SharedService
    ) {}

    ngOnInit() {
        this.sharedService.captchaKeys
        .pipe(
            takeUntil(this.componentDestroyed)
        )
        .subscribe((keys: CaptchaKeys) => {
            this.showInvisibleCaptchaQuestion = !!keys.invisible_recaptcha_site_key;
            this.showVisibleCaptchaQuestion = !!keys.v2_recaptcha_site_key;
            this.defaultCaptchaType = this.showVisibleCaptchaQuestion ? SurveyCaptchaType.VISIBLE : SurveyCaptchaType.INVISIBLE ;
            this.createFormGroup();
            this.createQuestionItemObject();
            this.triggerValueChanges();
        });

    }

    triggerValueChanges() {
        const control = this.form.controls['theme'];
        control.setValue(control.value || this.defaultCaptchaTheme);
    }

    private createFormGroup(): void {
        if (!this.questionItem) {
            this.questionItem = <CaptchaItem>{};
        }

        this.form = this.fb.group({
            captcha_type: new UntypedFormControl(
                this.questionItem.captcha_type || this.defaultCaptchaType
            ),
            theme: new UntypedFormControl(
                this.questionItem.theme || this.defaultCaptchaTheme
            ),
            size: new UntypedFormControl(
                this.questionItem.size || this.defaultCaptchaSize
            )
        });
    }

    private createQuestionItemObject(): void {
        this.updateQuestionItemObject();
        this.form.valueChanges
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(() => {
                this.updateQuestionItemObject();
            });
    }

    private updateQuestionItemObject(): void {
        const control = this.form.controls;
        const questionItem = new CaptchaItem();
        questionItem.item_type = SurveyQuestionType.CAPTCHA;
        questionItem.captcha_type = control['captcha_type'].value;
        questionItem.theme = control['theme'].value;
        questionItem.size = control['size'].value;
        questionItem.is_required = true;
        this.sharedService.share('questionItemSettings', questionItem);
    }

    get checkCaptchaConfig() {
        const currentType = this.form.get('captcha_type').value;
        return !this.showInvisibleCaptchaQuestion && !this.showVisibleCaptchaQuestion ||
        (currentType === 'Visible' && !this.showVisibleCaptchaQuestion) ||
        (currentType === 'Invisible' && !this.showInvisibleCaptchaQuestion);
    }

    ngOnDestroy() {}
}
