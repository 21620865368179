<cb-main-dialog
    [title]="
        this.editMode
            ? ('SHARED.EDIT' | translate)
            : ('SHARED.ADD' | translate)
            + ' ' +
              ('NEW-FOLDER-DIALOG.FOLDER' | translate)
    "
    [mainButton]="
        this.editMode
            ? ('SHARED.SAVE' | translate)
            : ('SHARED.CREATE' | translate)
    "
    [cancelButton]="'SHARED.CANCEL' | translate"
    [disabled]="disableSubmitButton()"
    (onMain)="onSubmit()"
    (onCancel)="cancel()">
    <form [formGroup]="form" (ngSubmit)="onSubmit()" #f="ngForm" novalidate>
        <div>
            <label>{{ 'NEW-FOLDER-DIALOG.FOLDER_NAME' | translate }}</label>
            <mat-form-field class="dialog-form no-label no-top" appearance="outline">
                <mat-label>{{ 'NEW-FOLDER-DIALOG.FOLDER_NAME' | translate }}</mat-label>
                <input
                    matInput
                    formControlName="name"
                />
                <mat-error>
                    {{ 'NEW-FOLDER-DIALOG.PLEASE_FOLDER_NAME' | translate }}
                </mat-error>
            </mat-form-field>
        </div>
    </form>
</cb-main-dialog>
