<cb-spinner [show]="isLoading" [blockLayout]="true"></cb-spinner>

<div class="dialog-content" *ngIf="!isLoading">
    <cb-spinner [show]="isLoadingTemplates" [blockLayout]="true"></cb-spinner>
    <h2 mat-dialog-title>
        {{ 'FROM-TEMPLATE-DIALOG.DIALOG-TITLE' | translate }}
    </h2>
    <form [formGroup]="form" (ngSubmit)="onSubmit()" novalidate>
        <mat-form-field>
            <mat-label>{{ 'FROM-TEMPLATE-DIALOG.CHOOSE-TEMPLATE' | translate }}</mat-label>
            <mat-select
                *ngIf="teplateList"
                formControlName="templateId"
            >
                <mat-option *ngFor="let template of teplateList"
                            [value]="template.id">
                    {{template.name}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div class="si-responses-table cb-flex-table-container" *ngIf="templateQuestions">
            <mat-table [dataSource]="sortedTemplateQuestions" class="mat-elevation-z8">
                <ng-container matColumnDef="select">
                    <mat-header-cell *matHeaderCellDef>
                        <mat-checkbox
                            (change)="$event ? toggleAll() : null"
                            [checked]="selection.hasValue() && isAllSelected()"
                            [indeterminate]="
                                selection.hasValue() && !isAllSelected()
                            "
                        >
                        </mat-checkbox>
                    </mat-header-cell>
                    <mat-cell mat-cell *matCellDef="let row">
                        <mat-checkbox
                            (click)="$event.stopPropagation()"
                            (change)="$event ? selection.toggle(row) : null"
                            [checked]="selection.isSelected(row)"
                            [disabled]="!row.id"
                        ></mat-checkbox>
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="questionTitle">
                    <mat-header-cell *matHeaderCellDef>
                        {{ 'FROM-TEMPLATE-DIALOG.QUESTION-TITLE' | translate }}
                    </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{element.page_position - 1}}.{{element.position}}
                        {{clearQuestiontext(element.question_text || element.text)}}
                    </mat-cell>
                </ng-container>

                <ng-container matColumnDef="type">
                    <mat-header-cell *matHeaderCellDef>
                        {{ 'FROM-TEMPLATE-DIALOG.QUESTION-TYPE' | translate }}
                   </mat-header-cell>
                    <mat-cell *matCellDef="let element">
                        {{ 'FROM-TEMPLATE-DIALOG.TYPES.'+element.item_type | translate }}
                        </mat-cell
                    >
                </ng-container>

                <ng-container matColumnDef="actions">
                    <mat-header-cell *matHeaderCellDef>
                        {{ 'FROM-TEMPLATE-DIALOG.PREVIEW' | translate }}
                   </mat-header-cell>
                    <mat-cell *matCellDef="let item">
                        <button
                            type="button"
                            mat-icon-button
                            (click)="$event.stopPropagation(); openPreview(item)"
                        >
                            <cb-svg-icon
                                class="icon d-inline-block"
                                name="preview"
                            ></cb-svg-icon>
                        </button>
                    </mat-cell>
                </ng-container>

                <mat-header-row
                    *matHeaderRowDef="displayedColumns"
                ></mat-header-row>
                <mat-row
                    *matRowDef="let row; columns: displayedColumns"
                ></mat-row>
            </mat-table>
            <mat-paginator
                [length]="templateQuestions?.length"
                [pageSize]="pageSize"
                [pageSizeOptions]="[5, 10, 25, 100]"
                (page)="pageChanged($event)">
            </mat-paginator>
        </div>

        <div class="buttons-container">
            <button
                mat-button
                type="button"
                class="btn-cancel"
                mat-dialog-close
            >
                {{ 'SHARED.CLOSE' | translate }}
            </button>
            <button
                mat-raised-button
                type="submit"
                class="btn-submit"
                [disabled]="!selection.selected.length"
            >
                {{'SHARED.SAVE' | translate}}
            </button>
        </div>
    </form>
</div>
