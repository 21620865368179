import { Input, Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, UntypedFormControl, FormArray } from '@angular/forms';
import { SurveyQuestionType } from '../../../../../infrastructure/consts/surveys.consts';
import { SharedService } from '../../../../../infrastructure/services';
import { MatDialogRef } from '@angular/material/dialog';
import { QuestionItemDialogComponent } from '../question-item-dialog/question-item-dialog.component';
import { MatrixItem } from '../../../../../shared/models/survey-items/question-items/matrix';
import { SurveyEditorValidator } from '../../../../../infrastructure/validators/survey-editor.validators';
import { transformToCloudfrontUrl } from '../../../../../infrastructure/helpers/cloudfront.helper';
import { Subject } from 'rxjs';
import { AutoUnsubscribe } from '../../../../../shared/decorators/autoUnsubscribe.decorator';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'cb-matrix-item',
    templateUrl: './matrix-item.component.html',
    styleUrls: ['./matrix-item.component.scss']
})
@AutoUnsubscribe()
export class MatrixItemComponent implements OnInit, OnDestroy {
    @Input() matrixItem: MatrixItem;

    form: UntypedFormGroup;
    positionOptions: any[];
    labelPositionOptions: any[];
    itemPosition: string;
    textPosition: string;
    rowTextPosition: string;
    options: any[];
    addQuestionItemDialogRef: MatDialogRef<QuestionItemDialogComponent>;

    matrix = SurveyQuestionType.MATRIX;
    myOptions: any;
    private componentDestroyed = new Subject();

    constructor(
        private fb: UntypedFormBuilder,
        private sharedService: SharedService
    ) {}

    ngOnInit() {
        this.form = this.fb.group({
            options: this.fb.array([])
        });

        this.createFormGroup();

        this.options = [
            {
                text: 'Left',
                imageUrl:
                    transformToCloudfrontUrl('assets/icons/appearance/align-left-default.png')
            },
            {
                text: 'Center',
                imageUrl:
                    transformToCloudfrontUrl('assets/icons/appearance/align-center-default.png')
            },
            {
                text: 'Right',
                imageUrl:
                    transformToCloudfrontUrl('assets/icons/appearance/align-right-default.png')
            }
        ];

        this.createQuestionItemObject();
    }

    public itemPositionSelected(position): void {
        this.itemPosition = position;
        this.form.controls['item_position'].setValue(position);
    }

    public labelPositionSelected(position): void {
        this.textPosition = position;
        this.form.controls['question_text_position'].setValue(position);
    }

    public rowTextPositionSelected(position): void {
        this.rowTextPosition = position;
        this.form.controls['row_text_align'].setValue(position);
    }

    private createFormGroup(): void {
        if (!this.matrix) {
            this.matrixItem = <MatrixItem>{};
        }

        this.form = this.fb.group({
            alias: new UntypedFormControl(this.matrixItem.alias),
            html_class: new UntypedFormControl(this.matrixItem.html_class),
            grid_lines: new UntypedFormControl(this.matrixItem.grid_lines),
            item_position: new UntypedFormControl(this.matrixItem.item_position),
            question_text_position: new UntypedFormControl(
                this.matrixItem.question_text_position
            ),
            row_text_align: new UntypedFormControl(this.matrixItem.row_text_align),
            width: new UntypedFormControl(this.matrixItem.width)
        });

        this.itemPosition = this.matrixItem.item_position;
        this.textPosition = this.matrixItem.question_text_position;
        this.rowTextPosition = this.matrixItem.row_text_align;
    }

    private createQuestionItemObject(): void {
        this.updateQuestionItemObject();
        this.form.valueChanges
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(s => {
                this.updateQuestionItemObject();
            });
    }

    private updateQuestionItemObject(): void {
        const control = this.form.controls;
        const questionItem = new MatrixItem();
        questionItem.item_type = SurveyQuestionType.MATRIX;
        questionItem.alias = control['alias'].value;
        questionItem.html_class = control['html_class'].value;
        questionItem.question_text_position = control['question_text_position'].value;
        questionItem.row_text_align = control['row_text_align'].value;
        questionItem.item_position = control['item_position'].value;
        questionItem.width = control['width'].value;
        questionItem.grid_lines = control['grid_lines'].value;

        this.sharedService.share('questionItemSettings', questionItem);
    }

    public onNumericKeyUp(event: any) {
        return SurveyEditorValidator.validateNumericInput(event);
    }

    ngOnDestroy() {}
}
