import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, UntypedFormArray } from '@angular/forms';
import {
    MaxDiffItem,
    maxDiffItemOrientationEnum
} from '../../../models/survey-items/question-items/maxDiffItem';
import { Component, Input, OnInit, Output, EventEmitter, OnDestroy, ElementRef, AfterViewInit } from '@angular/core';
import { getEnabledChoices, getQuestionItemInnerCssClass } from '../../../../infrastructure/helpers/surveys.helper';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { AutoUnsubscribe } from '../../../decorators/autoUnsubscribe.decorator';
import { takeUntil } from 'rxjs/operators';
import { TakeSurveyData } from '../../../../infrastructure/consts/take-survey.consts';
import { PrintService } from '../../../../infrastructure/services';
import { ItemOption } from '../../../models/survey-items/question-items/itemOption';

@Component({
    selector: 'cb-max-diff-item-preview',
    templateUrl: './max-diff-item-preview.component.html',
    styleUrls: ['./max-diff-item-preview.component.scss']
})
@AutoUnsubscribe()
export class MaxDiffPreviewItemComponent implements AfterViewInit, OnInit, OnDestroy {
    @Input() questionItem: MaxDiffItem;
    @Output() updated = new EventEmitter<any>();
    form: UntypedFormGroup;
    sets: any;
    maxDiffItemOrientationEnum = maxDiffItemOrientationEnum;
    getQuestionItemInnerCssClass = getQuestionItemInnerCssClass;
    private componentDestroyed = new Subject();
    questionId = TakeSurveyData.QUESTION_ID;
    enabled_choices: ItemOption[];

    constructor(
        private fb: UntypedFormBuilder,
        private printService: PrintService,
        private elem: ElementRef
        ) {}

    ngOnInit() {
        this.enabled_choices = getEnabledChoices(this.questionItem.choices);

        if (
            this.questionItem.number_items_per_set !== this.enabled_choices.length ||
            this.questionItem.number_sets > 1
        ) {
            this.shuffle(this.enabled_choices);
        }
        this.createFormGroup();
        this.form.valueChanges
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(s => this.updated.emit(s));
        if (this.questionItem["answer"] && this.questionItem["answer"].sets) {
            this.updated.emit(this.form.value);
        }
    }

    ngAfterViewInit(): void {
        this.printService.waitForImageLoadingAndMarkReady(this.questionItem.id, this.elem);
    }

    private shuffle(a) {
        let j, x, i;
        for (i = a.length - 1; i > 0; i--) {
            j = Math.floor(Math.random() * (i + 1));
            x = a[i];
            a[i] = a[j];
            a[j] = x;
        }
        return a;
    }

    private createFormGroup(): void {
        this.form = this.fb.group({
            id: new UntypedFormControl(this.questionItem.id),
            sets: this.fb.array([])
        });
        const number_sets = this.questionItem.dataSets
            ? this.questionItem.dataSets.length
            : this.questionItem.number_sets;
        for (let index = 0; index < number_sets; index++) {
            const set = this.questionItem.sets
                ? this.questionItem.sets[index]
                : null;

            let bestChoices = set
                ? set.best_choices
                : this.questionItem.dataSets
                ? this.sortChoices(this.questionItem.dataSets[index].choices)
                : this.enabled_choices;

            let worstChoices = set
                ? set.worst_choices
                : this.questionItem.dataSets
                ? this.sortChoices(this.questionItem.dataSets[index].choices)
                : this.enabled_choices;

            const bestChoiceId = _.get(
                this.questionItem,
                `answer.sets[${index}].best_choice_id`
            );
            if (bestChoiceId) {
                bestChoices = this.setCheckedState(bestChoices, bestChoiceId);
            }

            const worstChoiceId = _.get(
                this.questionItem,
                `answer.sets[${index}].worst_choice_id`
            );
            if (worstChoiceId) {
                worstChoices = this.setCheckedState(
                    worstChoices,
                    worstChoiceId
                );
            }

            const control = new UntypedFormGroup({
                best_choices: this.addChoices(bestChoices),
                worst_choices: this.addChoices(worstChoices)
            });
            (<UntypedFormArray>this.form.get('sets')).push(control);
        }
        this.sets = this.form.get('sets')['controls'];
    }

    resetForm() {
        this.sets.forEach(control => {
            this.resetControlCheckedValue(control, 'best_choices');
            this.resetControlCheckedValue(control, 'worst_choices');
        });
    }

    resetControlCheckedValue(control: any, name: string): any {
        control.get(name).controls.forEach(element => {
            element.get('checked').reset();
        });
    }

    setCheckedState(_choices, choiceId) {
        const choices = _.cloneDeep(_choices);
        const choice = _.find(choices, { id: choiceId }) as any;
        if (choice) {
            choice.checked = true;
        }
        return choices;
    }

    private sortChoices(choices: number[]) {
        const sorted = [];
        choices.forEach(ch => {
            sorted.push(this.questionItem.choices.find(x => x.id === ch));
        });
        return sorted;
    }

    public isDisabled(choiceIndex, setIndex, groupName) {
        const controlName =
            groupName === 'best_choices' ? 'worst_choices' : 'best_choices';
        return (
            (<UntypedFormArray>(
                this.form.get('sets')['controls'][setIndex].get(controlName)
            ))
                .at(choiceIndex)
                .get('checked').value === true
        );
    }

    public onGroupValueChanged($event, setIndex, groupName) {
        this.form
            .get('sets')
            ['controls'][setIndex].get(groupName)
            ['controls'].forEach(item => {
                if (item.controls['id'].value === $event.value) {
                    item.controls['checked'].setValue(true);
                    const opositeControl = this.form.get('sets')['controls'][setIndex]
                        .get(this.getOpositeGroup(groupName))['controls']
                        .find(x => x.controls['id'].value === $event.value);
                    if (opositeControl.value.checked) {
                        opositeControl.controls['checked'].setValue(false);
                    }
                    return;
                }
                item.controls['checked'].setValue(false);
            });
    }

    public getCheckedPropertyValue(choiceIndex, setIndex, groupName) {
        return (<UntypedFormArray>(
            this.form.get('sets')['controls'][setIndex].get(groupName)
        ))
            .at(choiceIndex)
            .get('checked').value;
    }

    private addChoices(choices) {
        const options = this.fb.array([]);
        choices.forEach(choice => {
            options.push(
                this.fb.group({
                    id: new UntypedFormControl(choice.id),
                    text: new UntypedFormControl(choice.text),
                    checked: new UntypedFormControl(
                        choice.checked ? choice.checked : null
                    )
                })
            );
        });
        return options;
    }
    
    private getOpositeGroup(groupName: string){
        return groupName === 'best_choices' ? 'worst_choices' : 'best_choices';
    }
    ngOnDestroy() {}
}
