import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { SurveyQuestionType } from '../../../../../infrastructure/consts/surveys.consts';
import { SharedService } from '../../../../../infrastructure/services';
import { SurveyEditorValidator } from '../../../../../infrastructure/validators/survey-editor.validators';
import { MatrixSumTotalItem } from '../../../../../shared/models/survey-items/question-items/sumTotalItem';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { AutoUnsubscribe } from '../../../../../shared/decorators/autoUnsubscribe.decorator';
import { takeUntil } from 'rxjs/operators';

@Component({
    selector: 'cb-sum-total-item',
    templateUrl: './sum-total-item.component.html',
    styleUrls: ['./sum-total-item.component.scss']
})
@AutoUnsubscribe()
export class SumTotalItemComponent implements OnInit, OnDestroy {
    @Input() questionItem: MatrixSumTotalItem;
    defaultMinValue = '';
    defaultMaxValue = '';
    defaultAnswerFormatValue = 'Numeric';
    defaultOperatorValue = 'Equal';
    defaultTotalValue = 0;
    editorText = '';
    private componentDestroyed = new Subject();

    public form: UntypedFormGroup;

    constructor(
        private fb: UntypedFormBuilder,
        private sharedService: SharedService
    ) {}

    ngOnInit() {
        this.createFormGroup();
        this.createQuestionItemObject();
    }

    private createFormGroup(): void {
        let isAllQuestionsRequired = false;
        if (!this.questionItem) {
            this.questionItem = <MatrixSumTotalItem>{};
        }
        if (!this.questionItem.id) {
            isAllQuestionsRequired = this.sharedService.surveyRespondentSettings.getValue() ?
            this.sharedService.surveyRespondentSettings.getValue().makeQuestionsRequired : false;
        }
        this.form = this.fb.group({
            alias: new UntypedFormControl(this.questionItem.alias),
            answer_format: new UntypedFormControl(
                this.questionItem.answer_format || this.defaultAnswerFormatValue
            ),
            min_value: new UntypedFormControl(
                this.questionItem.min_value || this.defaultMinValue
            ),
            max_value: new UntypedFormControl(
                this.questionItem.max_value || this.defaultMaxValue
            ),
            default_text: new UntypedFormControl(this.questionItem.default_text || ''),
            operator: new UntypedFormControl(
                this.questionItem.operator || this.defaultOperatorValue
            ),
            is_required: new UntypedFormControl(this.questionItem.is_required || isAllQuestionsRequired),
            is_soft_required: new UntypedFormControl(this.questionItem.softRequiredEnabled ? this.questionItem.is_soft_required : false),
            total_value: new UntypedFormControl(
                this.questionItem.total_value || this.defaultTotalValue
            )
        });
    }

    private createQuestionItemObject(): void {
        this.updateQuestionItemObject();
        this.form.valueChanges
            .pipe(takeUntil(this.componentDestroyed))
            .subscribe(() => {
                this.updateQuestionItemObject();
            });
    }

    private updateQuestionItemObject(): void {
        const control = this.form.controls;
        const questionItem = new MatrixSumTotalItem();
        questionItem.item_type = SurveyQuestionType.SUM_TOTAL;
        questionItem.alias = control['alias'].value;
        questionItem.answer_format = control['answer_format'].value;
        questionItem.min_value = control['min_value'].value;
        questionItem.max_value = control['max_value'].value;
        questionItem.default_text = control['default_text'].value;
        questionItem.operator = control['operator'].value;
        questionItem.is_required = control['is_required'].value;
        questionItem.is_soft_required = control['is_soft_required'].value;
        questionItem.total_value = control['total_value'].value;
        this.sharedService.share('questionItemSettings', questionItem);
    }

    public onMergeDefaultText(updatedText) {
        const field = this.form.controls['default_text'];
        field.setValue(`${field.value}${updatedText}`);
    }

    ngOnDestroy() {}
}
