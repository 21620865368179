<div class="form-section border-top" [formGroup]="parentForm">
    <cb-question-collapsed-panel
        title="{{ 'SURVEY-EDITOR.SHARED.BEHAVIOR' | translate }}"
        [expanded]="expanded"
    >
        <div class="form-fields-group">
            <div class="form-fields-container-outer">
                <div class="form-fields-container-inner">
                    <mat-checkbox
                        class="cb-form-field"
                        formControlName="is_required"
                    >
                        {{ 'SURVEY-EDITOR.ITEMS.CONTACT-FORM.FORM-FIELDS-REQUIRED' | translate }}
                    </mat-checkbox>
                </div>
                <cb-help-icon [tooltip]="'SURVEY-EDITOR.SHARED.TOOLTIPS.REQUIRED' | translate"></cb-help-icon>
            </div>
        </div>
        <div class="form-fields-group">
            <div class="form-fields-container-outer">
                <div class="form-fields-container-inner">
                    <mat-form-field class="cb-form-field">
                        <mat-label>{{
                                'SURVEY-EDITOR.SHARED.ALIAS' | translate
                            }}</mat-label>
                        <input
                            matInput
                            formControlName="alias"
                        />
                    </mat-form-field>
                </div>
                <cb-help-icon class="alias-help" [tooltip]="'SURVEY-EDITOR.SHARED.TOOLTIPS.ALIAS-QUESTION' | translate"></cb-help-icon>
            </div>
            <div class="form-fields-container-outer" *ngIf="showCustomCss">
                <div class="form-fields-container-inner">
                    <mat-form-field
                        class="cb-form-field"
                    >
                        <mat-label>{{
                                'SURVEY-EDITOR.SHARED.CSS-CLASS' | translate
                            }}</mat-label>
                        <input
                            matInput
                            formControlName="html_class"
                        />
                    </mat-form-field>
                </div>
            </div>
        </div>

        <ng-content></ng-content>

    </cb-question-collapsed-panel>
</div>
